import React, { useEffect, useState, Fragment, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { Typography, Box, Button } from '@mui/material';
import { ExpandMoreOutlined, ExpandLessOutlined } from '@mui/icons-material';
import globalVar from '../../global-constanta';
import { DOC_URL } from '../../api/IWRMServices';
import { useAppContext } from '../../context/AppContext';
import { GET_STATUS_2020_SUBMISSION_URL } from '../../api/IWRMServices'
import ReactMapGl from '../../components/deckgl/ReactMapGl';
import { specificColor } from '../../utils/HelperFunction';

const DataCollection = () => {

  const {
    state: { submission2020Status },
    action: { setLoading, setSubmission2020Status }
  } = useAppContext()

  const [geoJsonArray, setGeoJsonArray] = useState([])
  const [expands, setExpands] = useState([
    { id: 1, value: true },
    { id: 2, value: false },
    { id: 3, value: false },
    { id: 4, value: false },
    { id: 5, value: false },
    { id: 6, value: false },
  ]);

  const handleExpand = id => {
    setExpands(prevState =>
      prevState.map(item =>
        item.id === id ? { ...item, value: !item.value } : item,
      ),
    );
  };

  const getStatus2020Submission = useCallback(async () => {
    setLoading(true);
    try {
      let res = await fetch(GET_STATUS_2020_SUBMISSION_URL);
      let obj = await res.json();
      setSubmission2020Status(JSON.parse(obj));
    } catch (error) {
      // Handle error if necessary
    }
    setLoading(false);
  }, [setLoading, setSubmission2020Status]);

  useEffect(() => {
    if (submission2020Status?.length > 0) return
    getStatus2020Submission()
  }, [getStatus2020Submission, submission2020Status?.length])

  useEffect(() => {
    if (submission2020Status && submission2020Status.length > 0) {

      const geoJsonArray = submission2020Status.map(item => ({
        color: specificColor(item.Status),
        name: item.Country,
        geoJsonShape: {
          type: item.Type,
          coordinates: item.Coordinates,
        }
      }));

      setGeoJsonArray(geoJsonArray);
    }
  }, [submission2020Status]);

  const trackGAButton = (country, id, name) => {
    ReactGA.event({
      category: 'Download',
      action: `Download ${name}`,
      label: `${country} - Version ${id}`,
    });
  };

  return (
    <Fragment>
      <Helmet>
        <title>IWRM Data Portal - Data Collection</title>
        <meta name="description" content="These materials are primarily intended for national SDG indicator 6.5.1 Focal Points, their colleagues and other stakeholders involved, or interested, in SDG 6.5.1 reporting." />
        <meta name="keywords" content="IWRM, SDG 6, SDG 6.5.1, water management, sustainable development, data collection IWRM, data collection SDG 6, data collection SDG 6.5.1" />
      </Helmet>
      <div className="main-container" style={{ padding: '0px 20px' }}>
        <div className="one-column">
          <div className="support-programme-title">SDG 6.5.1 indicator official reporting materials</div>
          <div className="two-columns">
            <div className="left-side" style={{ marginRight: 5 }}>
              <div className="row">
                <div
                  className="col s12"
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    boxSizing: 'border-box',
                  }}
                >
                  <Box marginBottom={2}>
                    <Typography component="p" fontSize={12}>
                      These materials are primarily intended for national
                      SDG indicator 6.5.1 Focal Points, their colleagues and
                      other stakeholders involved, or interested, in SDG
                      6.5.1 reporting. All materials are available in
                      English (EN), Spanish (ES), French (FR), Arabic (AR),
                      Russian (RU), Portuguese (PT), and Chinese (ZH),
                      unless stated otherwise.
                    </Typography>
                  </Box>
                  <div className="support-body-container">
                    <div style={{ backgroundColor: 'white', flex: 1 }}>
                      <div className="support-desc-container">
                        <div className="accordion-container">
                          <div
                            className={
                              expands[0].value
                                ? 'accordion-header-active'
                                : 'accordion-header'
                            }
                            onClick={() => handleExpand(1)}
                          >
                            <div className="accordion-title">
                              2023 Key reporting materials (essential)
                            </div>
                            <div>
                              {expands[0].value ? (
                                <ExpandLessOutlined />
                              ) : (
                                <ExpandMoreOutlined />
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              expands[0].value
                                ? 'accordion-body'
                                : 'accordion-body-hide'
                            }
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box p={1} flex={1}>
                                <Typography variant="body2" component="p">
                                  <strong>2023 SDG 6.5.1 Survey</strong>
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                              >
                                {globalVar.LANGUAGE_VERSION.map(item => (
                                  <Button
                                    key={item.id}
                                    href={`${DOC_URL}/${item.country}/SDG651_2023_IWRM_Survey_${item.id}.docx`}
                                    onClick={() =>
                                      trackGAButton(
                                        item.country,
                                        item.id,
                                        `SDG651_2023_IWRM_Survey_${item.id
                                        }`,
                                      )
                                    }
                                    style={{
                                      color: '#00aeef',
                                      minWidth: 20,
                                      backgroundColor: 'transparent',
                                      padding: 0,
                                      fontSize: 12
                                    }}
                                    title={`${item.country} IWRM survey final`}
                                  >
                                    {item.id}
                                  </Button>
                                ))}
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box p={1} flex={1}>
                                <Typography variant="body2" component="p">
                                  <strong>2023 Monitoring Guide</strong>
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                              >
                                {globalVar.LANGUAGE_VERSION.map(item => (
                                  <Button
                                    key={item.id}
                                    href={`${DOC_URL}/${item.country}/SDG651_2023_Monitoring Guide_${item.id}.docx`}
                                    onClick={() =>
                                      trackGAButton(
                                        item.country,
                                        item.id,
                                        `SDG651_2023_Monitoring Guide_${item.id
                                        }`,
                                      )
                                    }
                                    style={{
                                      color: '#00aeef',
                                      minWidth: 20,
                                      backgroundColor: 'transparent',
                                      padding: 0,
                                      fontSize: 12
                                    }}
                                    title={`${item.country} monitoring guide final`}
                                  >
                                    {item.id}
                                  </Button>
                                ))}
                              </Box>
                            </Box>
                          </div>
                        </div>
                        <div className="accordion-container">
                          <div
                            className={
                              expands[1].value
                                ? 'accordion-header-active'
                                : 'accordion-header'
                            }
                            onClick={() => handleExpand(2)}
                          >
                            <div className="accordion-title">
                              2023 Monitoring guide in alternative formats
                              (optional)
                            </div>
                            <div>
                              {expands[1].value ? (
                                <ExpandLessOutlined />
                              ) : (
                                <ExpandMoreOutlined />
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              expands[1].value
                                ? 'accordion-body'
                                : 'accordion-body-hide'
                            }
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box p={1} flex={1}>
                                <Typography variant="body2" component="p">
                                  <strong>Short videos</strong> (YouTube,
                                  3-5 mins each)
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                              >
                                {globalVar.LANGUAGE_VERSION.map(item => (
                                  <Button
                                    key={item.id}
                                    href={item.video}
                                    style={{
                                      color: '#00aeef',
                                      minWidth: 20,
                                      backgroundColor: 'transparent',
                                      padding: 0,
                                      fontSize: 12
                                    }}
                                    title={`${item.country} Short videos`}
                                    target="_blank"
                                    onClick={() =>
                                      trackGAButton(
                                        item.country,
                                        item.id,
                                        `${item.country} Short videos`,
                                      )
                                    }
                                  >
                                    {item.id}
                                  </Button>
                                ))}
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box p={1} flex={1}>
                                <Typography variant="body2" component="p">
                                  <strong>Presentation (PPT)</strong>
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                              >
                                {globalVar.LANGUAGE_VERSION.map(item => (
                                  <Button
                                    key={item.id}
                                    href={`${DOC_URL}/${item.country}/SDG_651_2023_Monitoring_presentation_PPT_${item.id}.pptx`}
                                    onClick={() =>
                                      trackGAButton(
                                        item.country,
                                        item.id,
                                        `SDG_651_2023_Monitoring_presentation_PPT_${item.id
                                        }`,
                                      )
                                    }
                                    style={{
                                      color: '#00aeef',
                                      minWidth: 20,
                                      backgroundColor: 'transparent',
                                      padding: 0,
                                      fontSize: 12
                                    }}
                                    title={`${item.country} Presentation`}
                                  >
                                    {item.id}
                                  </Button>
                                ))}
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box p={1} flex={1}>
                                <Typography variant="body2" component="p">
                                  <strong>Presentation (PDF)</strong>
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                              >
                                {globalVar.LANGUAGE_VERSION.map(item => (
                                  <Button
                                    key={item.id}
                                    href={`${DOC_URL}/${item.country}/SDG_651_2023_Monitoring_presentation_PDF_${item.id}.pdf`}
                                    onClick={() =>
                                      trackGAButton(
                                        item.country,
                                        item.id,
                                        `SDG_651_2023_Monitoring_presentation_PDF_${item.id
                                        }`,
                                      )
                                    }
                                    style={{
                                      color: '#00aeef',
                                      minWidth: 20,
                                      backgroundColor: 'transparent',
                                      padding: 0,
                                      fontSize: 12
                                    }}
                                    title={`${item.country} Presentation (PDF)`}
                                  >
                                    {item.id}
                                  </Button>
                                ))}
                              </Box>
                            </Box>
                          </div>
                        </div>
                        <div className="accordion-container">
                          <div
                            className={
                              expands[2].value
                                ? 'accordion-header-active'
                                : 'accordion-header'
                            }
                            onClick={() => handleExpand(3)}
                          >
                            <div className="accordion-title">
                              Supporting materials (optional)
                            </div>
                            <div>
                              {expands[2].value ? (
                                <ExpandLessOutlined />
                              ) : (
                                <ExpandMoreOutlined />
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              expands[2].value
                                ? 'accordion-body'
                                : 'accordion-body-hide'
                            }
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              flex={1}
                            >
                              <Box
                                style={{ backgroundColor: '#f4f4f4' }}
                                width="100%"
                                boxSizing='border-box'
                                p={1}
                              >
                                <Typography
                                  variant="body2"
                                  component="p"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Communicating the survey
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box flex={1} p={1}>
                                  <Typography variant="body2" component="p">
                                    <strong>
                                      1-page 2023 Survey overview
                                    </strong>
                                  </Typography>
                                </Box>
                                <Box
                                  flex={1}
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  {globalVar.LANGUAGE_VERSION.map(item => (
                                    <Button
                                      key={item.id}
                                      href={`${DOC_URL}/${item.country}/SDG651_2023_Survey_Summary_${item.id}.docx`}
                                      onClick={() =>
                                        trackGAButton(
                                          item.country,
                                          item.id,
                                          `SDG651_2023_Survey_Summary_${item.id
                                          }`,
                                        )
                                      }
                                      style={{
                                        color: '#00aeef',
                                        minWidth: 20,
                                        backgroundColor: 'transparent',
                                        padding: 0,
                                        fontSize: 12
                                      }}
                                      title={`${item.country} Survey overview`}
                                    >
                                      {item.id}
                                    </Button>
                                  ))}
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box flex={1} p={1}>
                                  <Typography variant="body2" component="p">
                                    <strong>
                                      Country summaries
                                    </strong>
                                  </Typography>
                                </Box>
                                <Box flex={1} p={1}>
                                  <Typography
                                    variant="body2"
                                    component="div"
                                  >
                                    Available for each country, in each
                                    language, via the{' '}
                                    <a
                                      href="/country-reports"
                                      target="_blank"
                                      style={{
                                        textDecoration: 'none',
                                        color: '#00aeef',
                                        fontSize: '0.875rem',
                                      }}
                                    >
                                      country reports page
                                    </a>
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              flex={1}
                            >
                              <Box
                                style={{ backgroundColor: '#f4f4f4' }}
                                width="100%"
                                boxSizing='border-box'
                                p={1}
                              >
                                <Typography
                                  variant="body2"
                                  component="p"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Support to completing the survey
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box p={1} flex={1}>
                                  <Typography variant="body2" component="p">
                                    <strong>Calculation template</strong>{' '}
                                    (for dimension averages & targets)
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  flex={1}
                                >
                                  {globalVar.LANGUAGE_VERSION.map(item => {
                                    if (item.id === 'EN') {
                                      return (
                                        <Button
                                          key={item.id}
                                          href={`${DOC_URL}/${item.country}/SDG651_2023_Calculation_Template.xlsx`}
                                          onClick={() =>
                                            trackGAButton(
                                              item.country,
                                              item.id,
                                              `SDG651_2023_Calculation_Template_${item.country
                                              }`,
                                            )
                                          }
                                          style={{
                                            color: '#00aeef',
                                            minWidth: 20,
                                            backgroundColor: 'transparent',
                                            padding: 0,
                                            fontSize: 12
                                          }}
                                          title={`${item.country} Calculation template`}
                                        >
                                          {item.id}
                                        </Button>
                                      );
                                    }
                                    return (
                                      <Button
                                        key={item.id}
                                        style={{
                                          color: '#00aeef',
                                          minWidth: 20,
                                          backgroundColor: 'transparent',
                                          padding: 0,
                                        }}
                                        disabled
                                      >
                                        -
                                      </Button>
                                    );
                                  })}
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box p={1} flex={1}>
                                  <Typography variant="body2" component="p">
                                    <strong>Gender checklist</strong>{' '}
                                    (supporting discussion on question 2.2d)
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  flex={1}
                                >
                                  {globalVar.LANGUAGE_VERSION.map(item => (
                                    <Button
                                      key={item.id}
                                      href={`${DOC_URL}/${item.country}/SDG651_2023_Gender Checklist_${item.id}.docx`}
                                      onClick={() =>
                                        trackGAButton(
                                          item.country,
                                          item.id,
                                          `SDG651_2023_Gender Checklist_${item.country
                                          }`,
                                        )
                                      }
                                      style={{
                                        color: '#00aeef',
                                        minWidth: 20,
                                        backgroundColor: 'transparent',
                                        padding: 0,
                                        fontSize: 12
                                      }}
                                      title={`${item.country} Gender checklist`}
                                    >
                                      {item.id}
                                    </Button>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              flex={1}
                            >
                              <Box
                                style={{ backgroundColor: '#f4f4f4' }}
                                width="100%"
                                boxSizing='border-box'
                                p={1}
                              >
                                <Typography
                                  variant="body2"
                                  component="p"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Stakeholder consultation
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box p={1} flex={1}>
                                  <Typography variant="body2" component="p">
                                    <strong>
                                      Stakeholder Consultation Manual
                                    </strong>
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  flex={1}
                                >
                                  {globalVar.LANGUAGE_VERSION.map(item => (
                                    <Button
                                      key={item.id}
                                      href={`${DOC_URL}/${item.country}/SDG651_2023_Stakeholder Consultation Manual_${item.id}.docx`}
                                      onClick={() =>
                                        trackGAButton(
                                          item.country,
                                          item.id,
                                          `SDG651_2023_Stakeholder Consultation Manual_${item.country
                                          }`,
                                        )
                                      }
                                      style={{
                                        color: '#00aeef',
                                        minWidth: 20,
                                        backgroundColor: 'transparent',
                                        padding: 0,
                                        fontSize: 12
                                      }}
                                      title={`${item.country} Stakeholder Consultation Manual`}
                                    >
                                      {item.id}
                                    </Button>
                                  ))}
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box p={1} flex={1}>
                                  <Typography
                                    style={{ marginLeft: 20 }}
                                    variant="body2"
                                    component="p"
                                  >
                                    Annex 1 – Online IWRM Survey Tool
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  flex={1}
                                >
                                  {globalVar.LANGUAGE_VERSION.map(item => (
                                    <Button
                                      key={item.id}
                                      href={`${DOC_URL}/${item.country}/SDG651_2023_Stakeholder Consultation Manual_Annex 1_${item.id}.docx`}
                                      onClick={() =>
                                        trackGAButton(
                                          item.country,
                                          item.id,
                                          `SDG651_2023_Stakeholder Consultation Manual_Annex 1_${item.country
                                          }`,
                                        )
                                      }
                                      style={{
                                        color: '#00aeef',
                                        minWidth: 20,
                                        backgroundColor: 'transparent',
                                        padding: 0,
                                        fontSize: 12
                                      }}
                                      title={`${item.country} Online IWRM Survey Tool`}
                                    >
                                      {item.id}
                                    </Button>
                                  ))}
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box p={1} flex={1}>
                                  <Typography
                                    style={{ marginLeft: 20 }}
                                    variant="body2"
                                    component="p"
                                  >
                                    Annex 2 – Example Workshop Agenda
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  flex={1}
                                >
                                  {globalVar.LANGUAGE_VERSION.map(item => (
                                    <Button
                                      key={item.id}
                                      href={`${DOC_URL}/${item.country}/SDG651_2023_Stakeholder Consultation Manual_Annex 2_${item.id}.docx`}
                                      onClick={() =>
                                        trackGAButton(
                                          item.country,
                                          item.id,
                                          `SDG651_2023_Stakeholder Consultation Manual_Annex 2_${item.country
                                          }`,
                                        )
                                      }
                                      style={{
                                        color: '#00aeef',
                                        minWidth: 20,
                                        backgroundColor: 'transparent',
                                        padding: 0,
                                        fontSize: 12
                                      }}
                                      title={`${item.country} Example Workshop Agenda`}
                                    >
                                      {item.id}
                                    </Button>
                                  ))}
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box p={1} flex={1}>
                                  <Typography
                                    style={{ marginLeft: 20 }}
                                    variant="body2"
                                    component="p"
                                  >
                                    Annex 3 – Optional Consultation Report
                                    Template
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  flex={1}
                                >
                                  {globalVar.LANGUAGE_VERSION.map(item => (
                                    <Button
                                      key={item.id}
                                      href={`${DOC_URL}/${item.country}/SDG651_2023_Stakeholder Consultation Manual_Annex 3_${item.id}.docx`}
                                      onClick={() =>
                                        trackGAButton(
                                          item.country,
                                          item.id,
                                          `SDG651_2023_Stakeholder Consultation Manual_Annex 3_${item.country
                                          }`,
                                        )
                                      }
                                      style={{
                                        color: '#00aeef',
                                        minWidth: 20,
                                        backgroundColor: 'transparent',
                                        padding: 0,
                                        fontSize: 12
                                      }}
                                      title={`${item.country} Optional Consultation Report Template`}
                                    >
                                      {item.id}
                                    </Button>
                                  ))}
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box p={1} flex={1}>
                                  <Typography
                                    style={{ marginLeft: 20 }}
                                    variant="body2"
                                    component="p"
                                  >
                                    Annex 4 – Example Facilitator ToR
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  flex={1}
                                >
                                  {globalVar.LANGUAGE_VERSION.map(item => (
                                    <Button
                                      key={item.id}
                                      href={`${DOC_URL}/${item.country}/SDG651_2023_Stakeholder Consultation Manual_Annex 4_${item.id}.docx`}
                                      onClick={() =>
                                        trackGAButton(
                                          item.country,
                                          item.id,
                                          `SDG651_2023_Stakeholder Consultation Manual_Annex 4_${item.country
                                          }`,
                                        )
                                      }
                                      style={{
                                        color: '#00aeef',
                                        minWidth: 20,
                                        backgroundColor: 'transparent',
                                        padding: 0,
                                        fontSize: 12
                                      }}
                                      title={`${item.country} Example Facilitator`}
                                    >
                                      {item.id}
                                    </Button>
                                  ))}
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box p={1} flex={1}>
                                  <Typography variant="body2" component="p">
                                    <strong>
                                      Facilitator’s Training Course
                                    </strong>{' '}
                                    (Self-paced, free, online, specifically
                                    for 6.5.1 reporting (4-6hrs commitment))
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  flex={1}
                                >
                                  {globalVar.LANGUAGE_VERSION.map(item => {
                                    if (
                                      item.id === 'EN' ||
                                      item.id === 'ES' ||
                                      item.id === 'FR'
                                    ) {
                                      return (
                                        <Button
                                          key={item.id}
                                          href={item.course}
                                          onClick={() =>
                                            trackGAButton(
                                              item.country,
                                              item.id,
                                              `${item.course} ${item.country
                                              }`,
                                            )
                                          }
                                          style={{
                                            color: '#00aeef',
                                            minWidth: 20,
                                            backgroundColor: 'transparent',
                                            padding: 0,
                                            fontSize: 12
                                          }}
                                          title={`${item.country} Facilitator’s Training Course`}
                                          target="_blank"
                                        >
                                          {item.id}
                                        </Button>
                                      );
                                    }
                                    return (
                                      <Button
                                        key={item.id}
                                        style={{
                                          color: '#00aeef',
                                          minWidth: 20,
                                          backgroundColor: 'transparent',
                                          padding: 0,
                                          fontSize: 12
                                        }}
                                        disabled
                                      >
                                        -
                                      </Button>
                                    );
                                  })}
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        </div>
                        {/* <div className="accordion-container">
                              <div className={expands[3].value ? "accordion-header-active" : "accordion-header"} onClick={() => handleExpand(4)}>
                                <div className="accordion-title">
                                  SDG 6 IWRM Support Programme resources (optional)
                                </div>
                                <div>
                                  {expands[3].value ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                                </div>
                              </div>
                              <div className={expands[3].value ? "accordion-body" : "accordion-body-hide"}>
                                <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" flex={1}>
                                  <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
                                    <Box p={1} flex={1}>
                                      <Typography variant="body2" component="p"><strong>Support Package</strong></Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flex={1}>
                                      {
                                        globalVar.LANGUAGE_VERSION.map(item => {
                                          if (item.id === 'EN' || item.id === 'ES' || item.id === 'FR') {
                                            return (
                                              <Button
                                                key={item.id}
                                                // href={`${DOWNLOAD_SURVEY_DOC_URL}/${item.country}/Survey_Summary/pdf`}
                                                style={{ color: '#00aeef', minWidth: 20, backgroundColor: 'transparent', padding: 0 }}
                                                title={`${item.country} Support Package`}
                                              >
                                                {item.id}
                                              </Button>
                                            )
                                          } else {
                                            return (
                                              <Button
                                                key={item.id}
                                                style={{ color: '#00aeef', minWidth: 20, backgroundColor: 'transparent', padding: 0 }}
                                              >
                                                -
                                              </Button>
                                            )
                                          }
                                        })
                                      }
                                    </Box>
                                  </Box>
                                  <Box p={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
                                    <Typography variant="body2" component="p">Primarily for those countries receiving technical/financial support to strengthen the stakeholder consultation process, through Stage 1 of the <a href='/support-programme' target='_blank' style={{ textDecoration: 'none', color: '#00aeef' }}>SDG 6 IWRM Support Programme</a>, but materials are available to all. The Support Package makes use of many of the other supporting materials in this table, as well as providing additional guidance on engaging a facilitator, and specifying other requirements for receiving financial assistance.</Typography>
                                  </Box>
                                </Box>
                              </div>
                            </div> */}
                        <div className="accordion-container">
                          <div
                            className={
                              expands[3].value
                                ? 'accordion-header-active'
                                : 'accordion-header'
                            }
                            onClick={() => handleExpand(4)}
                          >
                            <div className="accordion-title">
                              Download all 2023 reporting materials (zip)
                            </div>
                            <div>
                              {expands[3].value ? (
                                <ExpandLessOutlined />
                              ) : (
                                <ExpandMoreOutlined />
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              expands[3].value
                                ? 'accordion-body'
                                : 'accordion-body-hide'
                            }
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              flex={1}
                            >
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box flex={1} p={1}>
                                  <Typography variant="body2" component="p">
                                    All 2023 reporting primary and
                                    supporting materials (zip)
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  flex={1}
                                >
                                  {globalVar.LANGUAGE_VERSION.map(item => (
                                    <Button
                                      key={item.id}
                                      href={`${DOC_URL}/${item.country}/All_2023_reporting_primary_and_supporting_materials_${item.id}.zip`}
                                      style={{
                                        color: '#00aeef',
                                        minWidth: 20,
                                        backgroundColor: 'transparent',
                                        padding: 0,
                                        fontSize: 12
                                      }}
                                      onClick={() =>
                                        trackGAButton(
                                          item.country,
                                          item.id,
                                          `All_2023_reporting_primary_and_supporting_materials_${item.id
                                          }`,
                                        )
                                      }
                                      title={`${item.country} All 2023 reporting`}
                                      target="_blank"
                                    >
                                      {item.id}
                                    </Button>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        </div>

                        <div className="accordion-container">
                          <div
                            className={
                              expands[4].value
                                ? 'accordion-header-active'
                                : 'accordion-header'
                            }
                            onClick={() => handleExpand(5)}
                          >
                            <div className="accordion-title">
                              2020 (OLD) reporting materials
                            </div>
                            <div>
                              {expands[4].value ? (
                                <ExpandLessOutlined />
                              ) : (
                                <ExpandMoreOutlined />
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              expands[4].value
                                ? 'accordion-body'
                                : 'accordion-body-hide'
                            }
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box p={1} flex={1}>
                                <Typography variant="body2" component="p">
                                  <strong>2020 SDG 6.5.1 Survey</strong>
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                              >
                                {globalVar.LANGUAGE_VERSION.map(item => (
                                  <Button
                                    key={item.id}
                                    href={`${DOC_URL}/${item.country}/SDG651_2020_IWRM_Survey_${item.id}.docx`}
                                    onClick={() =>
                                      trackGAButton(
                                        item.country,
                                        item.id,
                                        `SDG651_2020_IWRM_Survey_${item.id
                                        }`,
                                      )
                                    }
                                    style={{
                                      color: '#00aeef',
                                      minWidth: 20,
                                      backgroundColor: 'transparent',
                                      padding: 0,
                                      fontSize: 12
                                    }}
                                    title={`${item.country} IWRM survey final`}
                                  >
                                    {item.id}
                                  </Button>
                                ))}
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box p={1} flex={1}>
                                <Typography variant="body2" component="p">
                                  <strong>2020 Monitoring Guide</strong>
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                              >
                                {globalVar.LANGUAGE_VERSION.map(item => (
                                  <Button
                                    key={item.id}
                                    href={`${DOC_URL}/${item.country}/SDG651_2020_Monitoring Guide_${item.id}.docx`}
                                    onClick={() =>
                                      trackGAButton(
                                        item.country,
                                        item.id,
                                        `SDG651_2020_Monitoring Guide_${item.id
                                        }`,
                                      )
                                    }
                                    style={{
                                      color: '#00aeef',
                                      minWidth: 20,
                                      backgroundColor: 'transparent',
                                      padding: 0,
                                      fontSize: 12
                                    }}
                                    title={`${item.country} monitoring guide final`}
                                  >
                                    {item.id}
                                  </Button>
                                ))}
                              </Box>
                            </Box>
                          </div>
                        </div>

                        <div className="accordion-container">
                          <div
                            className={
                              expands[5].value
                                ? 'accordion-header-active'
                                : 'accordion-header'
                            }
                            onClick={() => handleExpand(6)}
                          >
                            <div className="accordion-title">
                              2017 (OLD) reporting materials
                            </div>
                            <div>
                              {expands[5].value ? (
                                <ExpandLessOutlined />
                              ) : (
                                <ExpandMoreOutlined />
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              expands[5].value
                                ? 'accordion-body'
                                : 'accordion-body-hide'
                            }
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box p={1} flex={1}>
                                <Typography variant="body2" component="p">
                                  <strong>2017 SDG 6.5.1 Survey</strong>
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                              >
                                {globalVar.LANGUAGE_VERSION.map(item => {
                                  if(item.id === "PT"){
                                    return (
                                      <Button
                                        key={item.id}
                                        style={{
                                          color: '#00aeef',
                                          minWidth: 20,
                                          backgroundColor: 'transparent',
                                          padding: 0,
                                          fontSize: 12
                                        }}
                                        disabled
                                      >
                                        -
                                      </Button>
                                    );
                                  }
                                  return(
                                  <Button
                                    key={item.id}
                                    href={`${DOC_URL}/${item.country}/SDG651_2017_IWRM_Survey_${item.id}.docx`}
                                    onClick={() =>
                                      trackGAButton(
                                        item.country,
                                        item.id,
                                        `SDG651_2017_IWRM_Survey_${item.id
                                        }`,
                                      )
                                    }
                                    style={{
                                      color: '#00aeef',
                                      minWidth: 20,
                                      backgroundColor: 'transparent',
                                      padding: 0,
                                      fontSize: 12
                                    }}
                                    title={`${item.country} IWRM survey final`}
                                  >
                                    {item.id}
                                  </Button>
                                  );
                                })}
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box p={1} flex={1}>
                                <Typography variant="body2" component="p">
                                  <strong>2017 Monitoring Guide</strong>
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                              >
                                {globalVar.LANGUAGE_VERSION.map(item => {
                                  if(item.id === "PT"){
                                    return (
                                      <Button
                                        key={item.id}
                                        style={{
                                          color: '#00aeef',
                                          minWidth: 20,
                                          backgroundColor: 'transparent',
                                          padding: 0,
                                          fontSize: 12
                                        }}
                                        disabled
                                      >
                                        -
                                      </Button>
                                    );
                                  }
                                  return (
                                  <Button
                                    key={item.id}
                                    href={`${DOC_URL}/${item.country}/SD651_2017_step_by_step_methodology_${item.id}.docx`}
                                    onClick={() =>
                                      trackGAButton(
                                        item.country,
                                        item.id,
                                        `SDG651_2017_Monitoring Guide_${item.id
                                        }`,
                                      )
                                    }
                                    style={{
                                      color: '#00aeef',
                                      minWidth: 20,
                                      backgroundColor: 'transparent',
                                      padding: 0,
                                      fontSize: 12
                                    }}
                                    title={`${item.country} monitoring guide final`}
                                  >
                                    {item.id}
                                  </Button>
                                  );
                                })}
                              </Box>
                            </Box>
                          </div>
                        </div>

                        {/* <div className="accordion-container">
                          <div
                            className={
                              expands[5].value
                                ? 'accordion-header-active'
                                : 'accordion-header'
                            }
                            onClick={() => handleExpand(6)}
                          >
                            <div className="accordion-title">
                              2017 (OLD) reporting materials
                            </div>
                            <div>
                              {expands[5].value ? (
                                <ExpandLessOutlined />
                              ) : (
                                <ExpandMoreOutlined />
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              expands[5].value
                                ? 'accordion-body'
                                : 'accordion-body-hide'
                            }
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              flex={1}
                            >
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Box flex={1} p={1}>
                                  <Typography variant="body2" component="p">
                                    Download the Survey and monitoring guide form 2017
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  flex={1}
                                >
                                  {globalVar.LANGUAGE_VERSION.map(item => (
                                    <Button
                                      key={item.id}
                                      onClick={() => {
                                        const surveyUrl = `${DOC_URL}/${item.country}/SDG651_2017_IWRM_Survey_${item.id}.docx`;
                                        const methodologyUrl = `${DOC_URL}/${item.country}/SD651_2017_step_by_step_methodology_${item.id}.docx`;

                                        if (item.id !== 'PT') {
                                          window.open(surveyUrl, '_blank');
                                          window.open(methodologyUrl, '_blank');
                                        }

                                        trackGAButton(
                                          item.country,
                                          item.id,
                                          `${item.course} ${item.country
                                          }`,
                                        )
                                      }}
                                      style={{
                                        color: '#00aeef',
                                        minWidth: 20,
                                        backgroundColor: 'transparent',
                                        padding: 0,
                                        fontSize: 12
                                      }}
                                      title={`${item.country} 2017 survey and monitoring guide form`}
                                    >
                                      {item.id}
                                    </Button>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side" style={{ marginLeft: 5 }}>
              <div
                style={{
                  boxSizing: 'border-box',
                  borderRadius: 0,
                  width: '100%',
                  height: '600px',
                }}
              >
                <ReactMapGl
                  geoJsonArray={geoJsonArray}
                  isInteractive={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default DataCollection