/* eslint-disable global-require */
module.exports = {
  undhiLightBlue: '#cef2ff',
  undhiBlue: '#00aeef',
  undhiDarkBlue: '#004165',
  ALERT_COLOR: '#ff4d4f',
  WARNING_COLOR: '#ffa940',
  NORMAL_COLOR: '#73d13d',
  NO_DATA_COLOR: 'rgba(0,0,0,0.5)',
  NORMAL: 'light',
  MAP_GOOGLE_KEY: 'AIzaSyBftGLBZsN8Qk8IY3dOzqkxKMnRDw484zU',
  MAP_GOOGLE_MAP_VERSION: '3.37',
  MAP_DEFAULT_TYPE: 'hybrid',
  MAP_MIN_ZOOM: 3,
  MAP_MAX_ZOOM: 24,
  MAP_DEFAULT_ZOOMING: 3,
  MAP_DEFAULT_COORDINATES: [24.214013864933438, 62.23258456387173],
  LATEST_SURVEY_YEAR: 2020,
  DEFAULT_SURVEY_INDICATOR: ['IWRM Degree of implementation: Final Score', 'SDG 6.5.1 Score: IWRM implementation', 'SDG 6.5.1 Score IWRM implementation'],
  DEFAULT_SURVEY_QUESTION_NUMBER: '6.5.1 Final Score',
  SDG_LEGEND: [
    {
      name: 'Very high (91 to 100)',
      color: '#0076b0',
      min: 91,
      max: 100,
    },
    {
      name: 'High (71 to 90)',
      color: '#69bcef',
      min: 71,
      max: 90,
    },
    {
      name: 'Medium-high (51 to 70)',
      color: '#81be76',
      min: 51,
      max: 70,
    },
    {
      name: 'Medium-low (31 to 50)',
      color: '#f3b90e',
      min: 31,
      max: 50,
    },
    {
      name: 'Low (11 to 30)',
      color: '#de771d',
      min: 11,
      max: 30,
    },
    {
      name: 'Very low (0 to 10)',
      color: '#8f181b',
      min: 0,
      max: 10,
    },
    {
      name: 'No data',
      color: '#e0e3e2',
      min: -1,
      max: -1,
    },
    {
      name: 'Not applicable',
      color: '#808080',
      min: -2,
      max: -1000000,
    },
  ],
  SDG_LEGEND_SCORE_PANEL: [
    {
      name: 'Not applicable',
      color: '#808080',
    },
    {
      name: 'No data',
      color: '#e0e3e2',
    },
    {
      name: 'Very high (100)',
      color: '#0076b0',
    },
    {
      name: 'High (80, 90)',
      color: '#69bcef',
    },
    {
      name: 'Medium-high (60, 70)',
      color: '#81be76',
    },
    {
      name: 'Medium-low (40, 50)',
      color: '#f3b90e',
    },
    {
      name: 'Low (20, 30)',
      color: '#de771d',
    },
    {
      name: 'Very low (0, 10)',
      color: '#8f181b',
    },
  ],
  DATA_COLLECTION_LEGEND: [
    // {
    //   name: 'Focal point confirmed',
    //   color: '#bae4bc',
    //   min: 91,
    //   max: 100,
    // },
    {
      name: 'Focal point confirmation pending',
      color: '#fcae91',
      min: 71,
      max: 90,
      dashed: false,
    },
    {
      name: 'Reporting in progress',
      color: '#E0F3DB', // '#67a9cf',
      min: 51,
      max: 70,
      dashed: false,
    },
    {
      name: '2023 data submitted',
      color: '#a1dab4', // '#1c9099',
      min: 31,
      max: 50,
      dashed: false,
    },
    {
      name: '2023 data finalized',
      color: '#41b6c4', // '#016c59',
      min: 11,
      max: 30,
      isPattern: false,
    },
    // {
    //   name: 'Submission finalized (2017 data resubmission)',
    //   color: '#34929d',
    //   min: 11,
    //   max: 30,
    //   patternColor: '#2b848f',
    //   isPattern: true,
    // },
    // {
    //   name: 'No data reported for 2020',
    //   color: '#d7d7d7',
    //   min: 0,
    //   max: 0,
    //   isPattern: false,
    // },
  ],
  MAPBOX_STYLE: "mapbox://styles/dhi-mike-development/clf6cwl3f000l01osmou0hzmb",
  MAPBOX_TOKEN: "pk.eyJ1IjoiZGhpLW1pa2UtZGV2ZWxvcG1lbnQiLCJhIjoiY2xnZXY1a28yMDdoMjNucXBnNWFiemxnaiJ9.VExQdy1FpChQV0laM1-FYQ",
  IWRM_COMPONENT: [
    {
      id: 'Section 1',
      text: 'Enabling Environment: section average'
    },
    {
      id: 'Section 2',
      text: 'Institutions and Participation: section average',
    },
    {
      id: 'Section 3',
      text: 'Management Instruments: section average'
    },
    {
      id: 'Section 4',
      text: 'Financing: section average'
    },
  ],
  SECTION_GROUP: [
    {
      id: 'I',
      name: 'Enabling Environment',
      icon: 'icon-logoenabling-environtment',
    },
    {
      id: 'II',
      name: 'Institutions and Participation',
      icon: 'icon-american-sign-language-interpreting',
    },
    {
      id: 'III',
      name: 'Management Instruments',
      icon: 'icon-logomanagement-instrument',
    },
    { id: 'IV', name: 'Financing', icon: 'icon-logofinancing' },
    { id: '-', name: 'FINAL INDICATOR 6.5.1 SCORE', icon: '' },
  ],
  LANGUAGE_VERSION: [
    {
      country: 'English',
      id: 'EN',
      video: 'https://youtube.com/playlist?list=PLPQBtot2gdlVBjDpmcQZX84x5cP5CdLKp',
      course: 'https://cap-net.org/sdg-6-iwrm-support-program/'
    },
    {
      country: 'Spanish',
      id: 'ES',
      video: 'https://www.youtube.com/playlist?list=PLPQBtot2gdlUnFXDR9joPyTemZgzGnih3',
      course: 'https://cap-net.org/programa-de-ods-651/'
    },
    {
      country: 'French',
      id: 'FR',
      video: 'https://youtube.com/playlist?list=PLPQBtot2gdlXq5b7zbPaR3baQ_NMbbcXU',
      course: 'https://cap-net.org/programme-de-soutien-a-odd-6-gire/'
    },
    {
      country: 'Arabic',
      id: 'AR',
      video: 'https://www.youtube.com/playlist?list=PLPQBtot2gdlVKKBULsNuN47DW1D3VC_rZ',
      course: ''
    },
    {
      country: 'Russian',
      id: 'RU',
      video: 'https://www.youtube.com/playlist?list=PLPQBtot2gdlXhrbo__FGrgFq_NPOAr90v',
      course: ''
    },
    {
      country: 'Portuguese',
      id: 'PT',
      video: 'https://youtube.com/playlist?list=PLPQBtot2gdlV1uVNnWuDWFQfZqeP_I9I1',
      course: ''
    },
    {
      country: 'Chinese',
      id: 'ZH',
      video: 'https://www.youtube.com/playlist?list=PLPQBtot2gdlVCXEu8w2vpP-VY_MbqQZWZ',
      course: ''
    }],
  COUNTRY_EXCEPTION: [
    'Hong Kong', 'Falkland Islands (Malvinas)', 'Abu Ramad', 'Bir Tawil', 'Olemi Triangle'
  ],
  COUNTRY_HIGHLIGHT: {
    China: ['China', 'Taiwan', 'Hong Kong'],
    'United Kingdom of Great Britain and Northern Ireland': ['United Kingdom of Great Britain and Northern Ireland', 'Falkland Islands (Malvinas)'],
    Egypt: ['Egypt', 'Bir Tawil'],
    Sudan: ['Sudan', 'Abu Ramad'],
    'South Sudan': ['South Sudan', 'Olemi Triangle']
  },
  CURRENT_AVAILABLE_STAKEHOLDER_2020: [
    'Bahamas',
    'Bangladesh',
    'Bostwana',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Central African Republic',
    'Chad',
    'China',
    'Congo',
    'Costa Rica',
    'Democratic Republic of the Congo',
    'El Salvador',
    'Gabon',
    'Gambia',
    'Georgia',
    'Ghana',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Honduras',
    'Indonesia',
    'Kenya',
    'Kyrgyzstan',
    'Laos',
    'Lebanon',
    'Lesotho',
    'Malawi',
    'Malaysia',
    'Moldova',
    'Mongolia',
    'Nepal',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'North Macedonia',
    'Pakistan',
    'Panama',
    'Paraguay',
    'Phillippines',
    'Rwanda',
    'Saint Lucia',
    'Sao Tome',
    'Senegal',
    'Slovenia',
    'Somalia',
    'Tajikistan',
    'Tanzania',
    'Trinidad and Tobago',
    'Tunisia',
    'Uganda',
    'Uzbekistan',
    'Vietnam',
    'Zambia'
  ],
  CURRENT_AVAILABLE_STAKEHOLDER_2023: [
    {
      country: 'Armenia',
      type: 'docx'
    },
    {
      country: 'Azerbaijan',
      type: 'docx'
    },
    {
      country: 'Belize',
      type: 'docx'
    },
    {
      country: 'Benin',
      type: 'docx'
    },
    {
      country: 'Botswana',
      type: 'docx'
    },
    {
      country: 'Burkina Faso',
      type: 'docx'
    },
    {
      country: 'Cambodia',
      type: 'docx'
    },
    {
      country: 'Cameroon',
      type: 'docx'
    },
    {
      country: 'Central African Republic',
      type: 'docx'
    },
    {
      country: 'Chad',
      type: 'docx'
    },
    {
      country: 'Chile',
      type: 'docx'
    },
    {
      country: 'China',
      type: 'docx'
    },
    {
      country: 'Colombia',
      type: 'docx'
    },
    {
      country: 'Comoros',
      type: 'docx'
    },
    {
      country: 'Congo',
      type: 'docx'
    },
    {
      country: 'Costa Rica',
      type: 'docx'
    },
    {
      country: 'Ecuador',
      type: 'docx'
    },
    {
      country: 'El Salvador',
      type: 'docx'
    },
    {
      country: 'Gabon',
      type: 'docx'
    },
    {
      country: 'Gambia',
      type: 'docx'
    },
    {
      country: 'Georgia',
      type: 'docx'
    },
    {
      country: 'Ghana',
      type: 'docx'
    },
    {
      country: 'Grenada',
      type: 'pdf'
    },
    {
      country: 'Guatemala',
      type: 'docx'
    },
    {
      country: 'Guinea Bissau',
      type: 'docx'
    },
    {
      country: 'Honduras',
      type: 'docx'
    },
    {
      country: 'Indonesia',
      type: 'docx'
    },
    {
      country: 'Kazakhstan',
      type: 'docx'
    },
    {
      country: 'Kenya',
      type: 'pdf'
    },
    {
      country: 'Kyrgyzstan',
      type: 'docx'
    },
    {
      country: 'Lesotho',
      type: 'docx'
    },
    {
      country: 'Liberia',
      type: 'docx'
    },
    {
      country: 'Malaysia',
      type: 'docx'
    },
    {
      country: 'Mali',
      type: 'docx'
    },
    {
      country: 'Mozambique',
      type: 'docx'
    },
    {
      country: 'Nepal',
      type: 'docx'
    },
    {
      country: 'Nicaragua',
      type: 'docx'
    },
    {
      country: 'Niger',
      type: 'docx'
    },
    {
      country: 'Nigeria',
      type: 'docx'
    },
    {
      country: 'Pakistan',
      type: 'docx'
    },
    {
      country: 'Panama',
      type: 'docx'
    },
    {
      country: 'Paraguay',
      type: 'docx'
    },
    {
      country: 'Philippines',
      type: 'docx'
    },
    {
      country: 'Rwanda',
      type: 'docx'
    },
    {
      country: 'Saint Lucia',
      type: 'docx'
    },
    {
      country: 'Sierra Leone',
      type: 'docx'
    },
    {
      country: 'Slovakia',
      type: 'pdf'
    },
    {
      country: 'Sri Lanka',
      type: 'docx'
    },
    {
      country: 'Sao Tome and Principle',
      type: 'doc'
    },
    {
      country: 'Togo',
      type: 'docx'
    },
    {
      country: 'Uzbekistan',
      type: 'docx'
    },
    {
      country: 'Vietnam',
      type: 'docx'
    },
    {
      country: 'Zambia',
      type: 'docx'
    },
  ],
  CURRENT_AVAILABLE_ACTION_PLAN_2017: [
    'Argentina',
    'Cameroon',
    'Democratic Republic of the Congo',
    'Ghana',
    'Grenada',
    'Kazakhstan',
    'Sao Tome and Principe',
    'Vietnam'
  ],
  CURRENT_AVAILABLE_ACTION_PLAN_2020: [
    'Armenia',
    'Kenya',
    'Nepal',
    'Nicaragua',
    'Panama',
    'United Republic of Tanzania'
  ],
  CURRENT_AVAILABLE_ACTION_PLAN_2023: [
    'Guatemala',
    'Paraguay',
    'Tunisia'
  ],
  CURRENT_AVAILABLE_FACTSHEETS: [
    'Argentina',
    'Cameroon',
    'Congo',
    'Costa Rica',
    'El Salvador',
    'Georgia',
    'Ghana',
    'Grenada',
    'Guatemala',
    'Honduras',
    'Kazakhstan',
    'Kenya',
    'Panama',
    'Sao Tome and Principe',
    'Uganda',
    'Viet Nam',
  ],
  AVAILABLE_LANGUAGES: [
    'English',
    'Arabic',
    'Chinese',
    'Russian',
    'French',
    'Spanish',
    'Portuguese',
  ],
  PUBLICATION_TYPE: [
    {
      id: 'Global',
      name: 'Global reports',
      link: '/publications/global',
      value: 0
    },
    {
      id: 'Regional',
      name: 'Regional reports',
      link: '/publications/regional',
      value: 1
    },
    {
      id: 'Methodology',
      name: 'Results',
      link: '/publications/results',
      value: 2
    },
    {
      id: 'Thematic',
      name: 'Thematic reports',
      link: '/publications/thematic',
      value: 3
    },
    {
      id: 'PolicyBriefs',
      name: 'Policy Briefs',
      link: '/publications/policy-briefs',
      value: 4
    },
    {
      id: 'ExtraResources',
      name: 'Extra resources',
      link: '/publications/extra-resources',
      value: 5
    }
  ]
};
