import React from 'react'
import { Box, Typography, Tabs, Tab } from '@mui/material';
import { useAppContext } from '../../context/AppContext';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

const YearTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 'bold'
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const TypeYearMenu = ({ yearValue, onReportYearChange }) => {

    const { action: { setYear } } = useAppContext();

    const yearList = [
        {
            label: 2017,
            ally: 0
        },
        {
            label: 2020,
            ally: 1
        },
        {
            label: 2023,
            ally: 2
        },
    ]

    return (
        <Box>
            <Tabs
                value={yearValue}
                onChange={onReportYearChange}
                aria-label="reportingYear"
                indicatorColor="primary"
                TabIndicatorProps={{
                    style: {
                        borderBottom: '1px solid #00a4ec',
                    },
                }}
                sx={{ boxSizing: 'border-box', paddingBottom: 1 }}
            >
                {
                    yearList.map((item, idx) => (
                        <YearTab
                            key={idx}
                            label={item.label}
                            {...a11yProps(item.ally)}
                            onClick={() => setYear(item.label)}
                        />
                    ))
                }
            </Tabs>
        </Box>
    )
}

export default TypeYearMenu