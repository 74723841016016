import React, { useState, useRef, useEffect } from 'react'
import { Box, Card, Slide, IconButton, Typography } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { downloadFullDatasetMap } from '../../utils/DownloadFunction';
import {
    getInitialYearValue,
    getInitialMapTypeValue,
    getYearValue,
    getGeographicArray,
    getEconomicArray
} from '../../utils/HelperFunction'
import { useAppContext } from '../../context/AppContext';
import TypeMapMenu from './TypeMapMenu';
import TypeYearMenu from './TypeYearMenu';
import ListQuestions from './ListQuestions';

const MapMenu = ({
    geoJsonArray,
    sections,
    questions,
    finalScoreQuestion,
    surveyIndicator,
    setSurveyIndicator
}) => {

    const {
        state: { year, mapType },
        action: { setCountrySelected }
    } = useAppContext();

    const [checked, setChecked] = useState(true)
    const containerRef = useRef(null);
    const [value, setValue] = useState(getInitialMapTypeValue(mapType))
    const [yearValue, setYearValue] = useState(getInitialYearValue(year));

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const handleMapTypeChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleReportYearChange = (event, newValue) => {
        setYearValue(newValue);
    };

    const handleSelectCountry = (event) => {
        setCountrySelected(event.target.value)
    }

    useEffect(() => {
        getYearValue(year, setYearValue)
    }, [year]);

    const geographicArray = getGeographicArray(geoJsonArray);
    const economicArray = getEconomicArray(geoJsonArray);

    return (
        <Box sx={{ width: 300, height: 'calc(100vh)', position: 'relative' }} ref={containerRef}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: checked ? 295 : '-10px',
                    backgroundColor: 'white',
                    width: 40,
                    height: 50,
                    zIndex: 3,
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <IconButton
                    aria-label="map-left-icon"
                    onClick={handleChange}
                >
                    {checked ? <ArrowBackIosNew /> : <ArrowForwardIos />}
                </IconButton>
            </Box>
            <Slide in={checked} container={containerRef.current} direction="right">
                <Card sx={{
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    padding: 2,
                    boxSizing: 'border-box'
                }}
                    elevation={1}
                >
                    <Box>
                        <Typography sx={{ fontSize: 12, fontWeight: 400, color: 'rgb(11, 69, 102)' }}>SDG indicator 6.5.1 reporting results</Typography>
                    </Box>

                    <TypeMapMenu
                        value={value}
                        onMapTypeChange={handleMapTypeChange}
                        onSelectCountry={handleSelectCountry}
                        geoJsonArray={geoJsonArray}
                        geographicArray={geographicArray}
                        economicArray={economicArray}
                        onDownloadFullDataset={downloadFullDatasetMap}
                    />

                    <TypeYearMenu yearValue={yearValue} onReportYearChange={handleReportYearChange} />

                    <ListQuestions
                        questions={questions}
                        sections={sections}
                        finalScoreQuestion={finalScoreQuestion}
                        surveyIndicator={surveyIndicator}
                        setSurveyIndicator={setSurveyIndicator}
                    />

                </Card>
            </Slide>
        </Box>
    );
}

export default MapMenu