import {
    GET_COUNTRY_ANSWER_URL
} from "../api/IWRMServices"

export const getCountryAnswers = async (country, year, setData) => {
    const countryAnswerUrl = `${GET_COUNTRY_ANSWER_URL}/${country}/${year}`
    try {
        let response = await fetch(countryAnswerUrl)
        let object = await response.json()
        setData(object)
    } catch (error) {
        console.log(error)
    }
}
