import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

const NewsCard = ({ url, title, text, link }) => {
    return (
        <Card sx={{
            width: 300,
            height: 250,
            marginLeft: 2,
            marginRight: 2,
            position: 'relative'
        }}>
            <CardMedia
                sx={{ height: 130 }}
                image={url}
                title={title}
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h2"
                    component="h2"
                    sx={{
                        fontSize: 16,
                        color: '#00aeef',
                        fontWeight: 'bold'
                    }}>
                    {title}
                </Typography>
                <Typography variant="body2" color="black" fontSize={12}>
                    {text}
                </Typography>
            </CardContent>
            <CardActions sx={{
                position: 'absolute',
                top: 110,
                right: 0,
            }}>
                <NavLink
                    to={link}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#00aeef',
                        width: 30,
                        height: 30,
                        borderRadius: '50%'
                    }}
                >
                    <ChevronRightIcon sx={{ color: 'white' }} />
                </NavLink>
            </CardActions>
        </Card>
    )
}

export default NewsCard