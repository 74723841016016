import React from 'react'
import { Card, InputBase, IconButton } from '@mui/material/'
import SearchIcon from '@mui/icons-material/Search';

const Search = ({ title, onSearch }) => {
    return (
        <Card
            sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 300,
                borderRadius: 2
            }}
        >
            <IconButton sx={{ p: '5px' }} aria-label="menu">
                <SearchIcon sx={{ color: 'black' }} />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1, fontSize:12 }}
                placeholder={title}
                onChange={onSearch}
            />
        </Card>
    )
}

export default Search