import React, { useState } from 'react'
import {useLocation} from "react-router-dom";
import {
    Card,
    CardActions,
    Typography,
    IconButton,
    Collapse,
    Divider,
    Box,
    Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import globalVar from '../../global-constanta'

const Legend = ({ isInteractive }) => {
    const location = useLocation();
    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ width: 250 }}>
            <CardActions
                disableSpacing
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <Typography
                    variant="body2"
                    color="black"
                    fontSize={12}
                    fontWeight={600}
                >
                    {location.pathname !== '/data-collection' ? 'Status of implementation' : 'Reporting status' }
                </Typography>
                <IconButton
                    expand={expanded.toString()}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider />
                <Stack flexDirection='column' padding={1}>
                    {
                        isInteractive ? (
                            globalVar.SDG_LEGEND.map(item => (
                                <Stack
                                    flexDirection='row'
                                    justifyContent='flex-start'
                                    alignItems='center'
                                    padding='2px 5px'
                                    key={item.name}
                                >
                                    <Box sx={{
                                        width: 10,
                                        height: 10,
                                        borderRadius: '50%',
                                        backgroundColor: item.color,
                                        marginRight: 1
                                    }} />
                                    <Typography fontSize={11}>{item.name}</Typography>
                                </Stack>
                            ))
                        ) : (
                            globalVar.DATA_COLLECTION_LEGEND.map(item => (
                                <Stack
                                    flexDirection='row'
                                    justifyContent='flex-start'
                                    alignItems='center'
                                    padding='2px 5px'
                                    key={item.name}
                                >
                                    {item.name !== 'Reporting in progress' && item.name !== '2023 data submitted' ? (
                                        <>
                                            <Box sx={{
                                                width: 10,
                                                height: 10,
                                                borderRadius: '50%',
                                                backgroundColor: item.color,
                                                marginRight: 1
                                             }} />
                                            <Typography fontSize={11}>{item.name}</Typography>
                                        </>
                                    ) : ''}
                                    
                                </Stack>
                            )))
                    }
                </Stack>
            </Collapse>
        </Card>
    )
}

export default Legend