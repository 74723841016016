import React from 'react'
import { Link } from 'react-router-dom';
import {
    Stack,
    Box,
    Typography,
    Tabs,
    Tab,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    Radio,
    RadioGroup,
    MenuItem,
    Select,
    Button,
} from '@mui/material';
import { FileDownload, Assignment } from '@mui/icons-material';
import { useAppContext } from '../../context/AppContext';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import globalVar from '../../global-constanta'

const MapTypeTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: 12,
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TypeMapMenu = ({
    value,
    onMapTypeChange,
    onSelectCountry,
    geoJsonArray,
    geographicArray,
    economicArray,
    onDownloadFullDataset,
}) => {

    const {
        state: {
            countrySelected,
            regionType,
            geographicRegionSelected,
            economicRegionSelected,
            mapType
        },
        action: {
            setCountrySelected,
            setRegionType,
            setGeographicRegionSelected,
            setEconomicRegionSelected,
            setMapType
        }
    } = useAppContext();

    const typeMapList = [
        {
            label: 'Countries',
            value: 'countries',
            ally: 0,
            color: mapType === 'countries' ? 'white' : '#00a4ec'
        },
        {
            label: 'Regions',
            value: 'regions',
            ally: 0,
            color: mapType === 'countries' ? '#00a4ec' : 'white'
        },
    ]

    const regionList = [
        {
            label: 'Geographic',
            value: 'geographic'
        },
        {
            label: 'Economic',
            value: 'economic'
        },
    ]

    return (
        <Box>
            <Box sx={{ borderBottom: '2px solid #00a4ec', marginTop: 1 }}>
                <Tabs
                    value={value}
                    onChange={onMapTypeChange}
                    aria-label="mapType"
                    indicatorColor="primary"
                    TabIndicatorProps={{
                        style: {
                            background: '#00a4ec',
                            height: '100%',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                        },
                    }}
                >
                    {
                        typeMapList.map((item, idx) => (
                            <MapTypeTab
                                key={idx}
                                label={item.label}
                                {...a11yProps(item.ally)}
                                onClick={() => setMapType(item.value)}
                                style={{
                                    flex: 1,
                                    zIndex: 2,
                                    fontSize: 11,
                                    fontWeight: 400,
                                    color: item.color
                                }}
                            />
                        ))
                    }
                </Tabs>
            </Box>
            {
                mapType === 'countries' ? (
                    <Stack sx={{ width: '100%' }}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel style={{ fontSize: 11 }}>Select a country</InputLabel>
                            <Select
                                labelId="select-country"
                                value={countrySelected}
                                onChange={onSelectCountry}
                                sx={{ fontSize: 11 }}
                            >
                                <MenuItem
                                    value=""
                                    sx={{ fontSize: 11 }}
                                    onClick={() => setCountrySelected('')}
                                >
                                    - Select country -
                                </MenuItem>
                                {geoJsonArray?.length > 0 && geoJsonArray
                                    .filter(
                                        country =>
                                            !globalVar.COUNTRY_EXCEPTION.includes(country.name),
                                    )
                                    .sort()
                                    .map(country => (
                                        <MenuItem
                                            key={country.name}
                                            value={country.name}
                                            onClick={() => setCountrySelected(country)}
                                            sx={{ fontSize: 11 }}
                                        >
                                            {country.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <Button
                            component={Link}
                            to='/country-reports'
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                textTransform: 'none',
                                color: '#00a4ec'
                            }}
                        >
                            <Assignment style={{ marginRight: 10, fontSize: 14 }} />
                            <Typography fontSize={12}>Go to the country reports</Typography>
                        </Button>
                        <Button
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                textTransform: 'none',
                                color: '#00a4ec'
                            }}
                            onClick={onDownloadFullDataset}
                        >
                            <FileDownload style={{ marginRight: 10, fontSize: 14 }} />
                            <Typography fontSize={12}>Download all country dataset (.xlsx)</Typography>
                        </Button>
                    </Stack>
                ) : (
                    <Stack sx={{ width: '100%' }}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <FormLabel
                                component="legend"
                                style={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: '#09334B',
                                }}
                            >
                                Choose a region type:
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                value={regionType}
                                onChange={e => setRegionType(e.target.value)}
                                sx={{ color: '#09334B' }}
                            >
                                {
                                    regionList.map((item, idx) => (
                                        <FormControlLabel
                                            key={idx}
                                            value={item.value}
                                            control={<Radio sx={{ color: '#00A4EC', '& .MuiSvgIcon-root': { fontSize: '11px' } }} />}
                                            label={<Typography sx={{ fontSize: '11px' }}>{item.label}</Typography>}
                                            labelPlacement="end"
                                        />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>

                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel style={{ fontSize: 11 }}>Select a region</InputLabel>
                            <Select
                                labelId="select-region"
                                value={regionType === 'geographic' ? geographicRegionSelected : economicRegionSelected}
                                onChange={onSelectCountry}
                                sx={{ fontSize: 11 }}
                            >
                                <MenuItem
                                    value=""
                                    sx={{ fontSize: 11 }}
                                    onClick={() => {
                                        if (regionType === 'geographic') {
                                            setGeographicRegionSelected('')
                                        } else {
                                            setEconomicRegionSelected('')
                                        }
                                    }}
                                >
                                    - Select region -
                                </MenuItem>
                                {
                                    (regionType === 'geographic' ? geographicArray : economicArray).filter(res => res !== '').sort().map(
                                        (region) => (
                                            <MenuItem
                                                key={region}
                                                value={region}
                                                onClick={() => {
                                                    if (regionType === 'geographic') {
                                                        setGeographicRegionSelected(region)
                                                    } else {
                                                        setEconomicRegionSelected(region)
                                                    }
                                                }}
                                                sx={{ fontSize: 11 }}
                                            >
                                                {region}
                                            </MenuItem>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>
                        <Button
                            component={Link}
                            to='/publications/regional'
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                textTransform: 'none',
                                color: '#00a4ec'
                            }}
                        >
                            <Assignment style={{ marginRight: 10, fontSize: 14 }} />
                            <Typography fontSize={11}>Regional analysis reports</Typography>
                        </Button>
                        <Button
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                textTransform: 'none',
                                color: '#00a4ec'
                            }}
                            onClick={onDownloadFullDataset}
                        >
                            <FileDownload style={{ marginRight: 10, fontSize: 14 }} />
                            <Typography fontSize={11}>Download all country dataset (.xlsx)</Typography>
                        </Button>
                    </Stack>
                )
            }
        </Box>
    )
}

export default TypeMapMenu