import React, { Fragment } from 'react'
import { Box, Typography, List, ListItemButton } from '@mui/material';
import { useAppContext } from '../../context/AppContext';
import globalVar from '../../global-constanta'

const ListQuestions = ({ questions, sections, finalScoreQuestion, surveyIndicator, setSurveyIndicator }) => {

    const {
        action: { setCountrySelected, setSelectedLayer, setSelectedPopup }
    } = useAppContext();

    return (
        <Box sx={{ overflowY: 'auto', maxHeight: 500, backgroundColor: 'lightgray' }}>
            {sections ? (
                <React.Fragment>
                    <List>
                        {
                            sections.filter(res => res.QuestionText === finalScoreQuestion?.QuestionText).map((component, i) => (
                                <ListItemButton
                                    sx={{
                                        backgroundColor: surveyIndicator === component.QuestionText ? '#00a4ec' : 'white',
                                        color: surveyIndicator === component.QuestionText ? 'white' : 'black',
                                        margin: '5px'
                                    }}
                                    onClick={() => {
                                        setSurveyIndicator(component.QuestionText)
                                        setCountrySelected('')
                                        setSelectedLayer(null)
                                        setSelectedPopup(false)
                                    }
                                    }
                                >
                                    <Typography sx={{ fontSize: 12 }}>{component.QuestionText}</Typography>
                                </ListItemButton>
                            ))
                        }
                        {globalVar.IWRM_COMPONENT.map((component, i) => (
                            <Fragment key={`comp${component.QuestionNumber}${i}`}>
                                {sections.map((section, sectionI) => (
                                    <Fragment
                                        key={`a${section.QuestionNumber}${sectionI}`}
                                    >
                                        {section.QuestionNumber.includes(component.id) ? (
                                            <Fragment
                                                key={`b${section.QuestionNumber}${sectionI}`}
                                            >
                                                {component.text !== section.QuestionText ? (
                                                    <Fragment
                                                        key={`c${section.QuestionNumber
                                                            }${sectionI}`}
                                                    >
                                                        <ListItemButton
                                                            sx={{
                                                                backgroundColor: surveyIndicator === section.QuestionText ? '#00a4ec' : 'white',
                                                                color: surveyIndicator === section.QuestionText ? 'white' : 'black',
                                                                margin: '5px'
                                                            }}
                                                            onClick={() => {
                                                                setSurveyIndicator(section.QuestionText)
                                                                setCountrySelected('')
                                                                setSelectedLayer(null)
                                                                setSelectedPopup(false)
                                                            }
                                                            }
                                                        >
                                                            <Typography sx={{ fontSize: 12 }}>{section.QuestionText}</Typography>
                                                        </ListItemButton>
                                                        {questions.map(
                                                            (question, questionI) => (
                                                                <Fragment
                                                                    key={`${section.QuestionNumber }${sectionI}${question.QuestionNumber} ${questionI}`}
                                                                >
                                                                    {question.QuestionNumber.replace(
                                                                        /\D/g,
                                                                        '',
                                                                    ) ===
                                                                        section.QuestionNumber.replace(
                                                                            /\D/g,
                                                                            '',
                                                                        ) ? (
                                                                        <ListItemButton
                                                                            sx={{
                                                                                backgroundColor: surveyIndicator === question.QuestionText ? '#00a4ec' : 'white',
                                                                                color: surveyIndicator === question.QuestionText ? 'white' : 'black',
                                                                                margin: '5px'
                                                                            }}
                                                                            onClick={() => {
                                                                                setSurveyIndicator(question.QuestionText)
                                                                                setCountrySelected('')
                                                                                setSelectedLayer(null)
                                                                                setSelectedPopup(false)
                                                                            }
                                                                            }
                                                                        >
                                                                            <Typography sx={{ fontSize: 12 }}>{`${question.QuestionNumber} ${question.QuestionText}`}</Typography>
                                                                        </ListItemButton>
                                                                    ) : null}
                                                                </Fragment>
                                                            ),
                                                        )}
                                                    </Fragment>
                                                ) : null}
                                            </Fragment>
                                        ) : null}
                                    </Fragment>
                                ))}
                            </Fragment>
                        ))}
                    </List>
                </React.Fragment>
            ) : null}
        </Box>
    )
}

export default ListQuestions