import React, { useEffect, useState, useMemo } from 'react';
import {
  GET_QUESTIONS_URL,
  GET_COUNTRY_ANSWER_URL,
  GET_COUNTRY_BASIC_INFO_URL
} from '../../api/IWRMServices';
import {
  Card,
  Grid,
  Box,
  Typography,
  Paper,
  Skeleton,
  CircularProgress
} from '@mui/material';
import globalVar from '../../global-constanta';
import { GetSDG651Scores } from '../../utils/HelperFunction';

let barChartData = { x: [], y: [], colors: [], number: [], global: [] };
let SDGLegendText = '';

const SdgBar = ({ country, year }) => {
  const [questions, setQuestions] = useState([]);
  const [countryAnswer, setCountryAnswer] = useState([]);
  const [countryBasicInfo, setCountryBasicInfo] = useState([]);
  const [countrySelected, setCountrySelected] = useState('');
  const latestSections = useMemo(() => (questions ? questions[1] : []), [questions]);

  

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [questionsRes, countryAnswersRes, countryBasicInfoRes] = await Promise.all([
          fetch(`${GET_QUESTIONS_URL}/${year}`),
          fetch(`${GET_COUNTRY_ANSWER_URL}/${country}/${year}`),
          fetch(`${GET_COUNTRY_BASIC_INFO_URL}/${country}/${year}`)
        ]);
  
        const questionsData = await questionsRes.json();
        const countryAnswersData = await countryAnswersRes.json();
        const countryBasicInfoData = await countryBasicInfoRes.json();
  
        setQuestions(questionsData);
        setCountryAnswer(countryAnswersData);
        setCountryBasicInfo(countryBasicInfoData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllData();
  }, [year, country]);

  useEffect(() => {
    if (countryAnswer.length > 0) {
      const countryAnswerTemp = [];
      const countryAnswerName = countryAnswer.map(item => item.Country.Name);
      for (let i = 0; i < countryAnswerName.length; i++) {
        if (countryAnswerTemp.indexOf(countryAnswerName[i]) === -1) {
          countryAnswerTemp.push(countryAnswerName[i]);
        }
      }
      setCountrySelected(countryAnswerTemp[0]);
      barChartData = GetSDG651Scores(countryAnswer, latestSections, false);
      const latestNumber = [];
      const latestGlobal = [];
      const latestRegionNumber = [];
      globalVar.SECTION_GROUP.forEach((item, i) => {
        if (countryBasicInfo) {
          latestNumber.push(item.id);
          if (i < globalVar.SECTION_GROUP.length - 1) {
            const filtered = countryBasicInfo.GlobalAverage?.filter(
              fil => fil.Id === `Section ${i + 1}`,
            )[0];
            latestGlobal.push(filtered?.Number);
            latestRegionNumber.push(filtered?.RegionNumber);
          } else {
            const filtered = countryBasicInfo.GlobalAverage?.filter(
              fil => fil.Id === 'Section 6.5.1 Final score',
            )[0];
            latestGlobal.push(filtered?.Number);
            latestRegionNumber.push(filtered?.RegionNumber);
          }
        }
      });
      barChartData.number = latestNumber;
      barChartData.global = latestGlobal;
      barChartData.regionNumber = latestRegionNumber;
      const obj = globalVar.SDG_LEGEND.filter(
        c => barChartData.x[4] >= c.min && barChartData.x[4] <= c.max,
      )[0];
      if (obj) {
        SDGLegendText = obj.name;
      }
    }
  }, [questions, countryAnswer, countryBasicInfo, latestSections]);

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ gap: 4 }}
        >
          {countrySelected === country ? (
            barChartData.x.map((item, idx) => {
              if (idx < barChartData.x.length - 1) {
                return (
                  <Grid key={idx}>
                    <Card style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      width: '250px',
                      flex:1,
                      height: '80px',
                    }}>
                      <Box sx={{
                        position: 'relative',
                        display: 'flex',
                        backgroundColor: 'lightgray',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        margin: '10px'
                      }}>
                        <CircularProgress
                          variant="determinate"
                          value={barChartData.x.includes(-1)
                            ? null
                            : barChartData.x[idx]}
                          style={{
                            color: barChartData.colors[idx],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '60px',
                            height: '60px'
                          }}
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            width: '50px',
                            height: '50px'
                          }}
                        >
                          {barChartData.x.includes(-1)
                            ? (
                              <Typography variant="caption" component="div" color="black" style={{ fontSize: 10, fontWeight: 400 }}>
                                No Data
                              </Typography>
                            )
                            :
                            <Typography variant="caption" component="div" color="black" style={{ fontSize: 12, fontWeight: 500 }}>
                              {barChartData.x[idx]}
                            </Typography>
                          }
                        </Box>
                      </Box>
                      <Box style={{alignContent: 'center' }}>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          style={{
                            display: 'block',
                            fontSize: 11,
                            textTransform: 'uppercase',
                            fontWeight: 400,
                            color: '#09334B',
                          }}
                        >
                          {barChartData.y[idx]}
                        </Typography>
                        <Typography
                          variant="caption"
                          component="span"
                          style={{
                            display: 'block',
                            fontSize: 10,
                            color: '#86A2B3',
                          }}
                        >
                          Global Average: {barChartData.global[idx]}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                );
              }
              if (idx === barChartData.x.length - 1) {
                return (
                  <Grid key={idx}>
                    <Card style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      width: '250px',
                      flex: 1,
                      height: '80px',
                    }}>
                      <Box
                        style={{
                          backgroundColor: barChartData.x.includes(-1)
                            ? '#e0e3e2'
                            : barChartData.colors[idx],
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          margin: '10px'
                        }}
                      >
                        <div
                          style={{
                            fontSize: barChartData.x.includes(-1) ? 10 : 16,
                            fontWeight: 700,
                            color: barChartData.x.includes(-1)
                              ? 'white'
                              : 'black',
                          }}
                        >
                          {barChartData.x.includes(-1)
                            ? 'No Data'
                            : barChartData.x[idx]}
                        </div>
                      </Box>
                      <Box style={{alignContent: 'center'}}>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          style={{
                            display: 'block',
                            fontSize: 12,
                            textTransform: 'uppercase',
                            fontWeight: 400,
                            color: '#09334B',
                          }}
                        >
                          SDG 6.5.1 Score
                        </Typography>
                        <Typography
                          variant="caption"
                          component="span"
                          style={{
                            fontSize: 12,
                            display: 'block',
                            color: '#09334B',
                          }}
                        >
                          {barChartData.x.includes(-1)
                            ? null
                            : SDGLegendText.substring(
                              0,
                              SDGLegendText.indexOf('(') - 1,
                            )}
                        </Typography>
                        <Typography
                          variant="caption"
                          component="span"
                          style={{
                            display: 'block',
                            fontSize: 10,
                            color: '#86A2B3',
                          }}
                        >
                          Global Average: {barChartData.global[idx]}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                );
              }
              return null;
            })
          ) : (
            <Grid container spacing={1}>
              {[...Array(5)].map((_, index) => (
                <Grid item xs={2.2} key={index}>
                  <Skeleton variant="rectangular" width='100%' height='100px' />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid xs={12} item>
          <Grid container item xs={12} style={{ marginTop: 10, marginBottom: 5 }}>
            <Typography variant="subtitle2" component="span" sx={{ fontSize: 11 }}>
              <b>Degree of implementation (0-100)</b> (dimension averages and
              6.5.1 scores)
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {globalVar.SDG_LEGEND.map((item, idx) => (
              <Box style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginRight: 20,
              }} key={idx}>
                <Paper
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    marginRight: 5,
                    backgroundColor: item.color
                  }}
                />
                <Typography variant="caption" component="span" fontSize={10}>
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SdgBar;