import {
    DOWNLOAD_FULL_DATABASE_EXCEL,
    DOWNLOAD_NATIONAL_SUBMISSION_URL,
    DOWNLOAD_REPORTING_ROUND_URL,
    DOWNLOAD_BASELINE_SUMMARY_URL,
    DOWNLOAD_REPORTING_SUMMARY_URL,
    DOWNLOAD_COUNTRY_FACTSHEETS_PILOTS_URL,
    STAKEHOLDER_URL,
    ACTION_PLANS_URL
} from '../api/IWRMServices'
import globalVar from '../global-constanta'
import { downloadAnalytic, GAPush } from './DownloadAnalytic';

export const downloadFullDatasetMap = () => {
    GAPush('download', 'interactive_map', 'all_country_dataset_en_map')
    window.open(DOWNLOAD_FULL_DATABASE_EXCEL);
}

export const downloadFullDatasetCountry = () => {
    GAPush('download', 'country_reports', 'all_country_dataset_en_country-pages')
    window.open(DOWNLOAD_FULL_DATABASE_EXCEL);
}

export const downloadFullReport = (year, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023) => {
    if (year === 2017 && participatedInSurvey2017 === 'YES') {
        const nationalSubmissionUrl = `${DOWNLOAD_NATIONAL_SUBMISSION_URL}/${countryName}`
        downloadAnalytic('Full Report 2017', `Download Full Report ${countryName} 2017`)
        window.open(nationalSubmissionUrl)
    }
    if (year === 2020 && participatedInSurvey2020 === 'YES') {
        const reportingRoundUrl = `${DOWNLOAD_REPORTING_ROUND_URL}/${countryName}/2020`
        downloadAnalytic('Full Report 2020', `Download Full Report ${countryName} 2020`)
        window.open(reportingRoundUrl)
    }
    // make sure the download full report 2023 url
    if (year === 2023 && participatedInSurvey2023 === 'YES') {
        const reportingRoundUrl = `${DOWNLOAD_REPORTING_ROUND_URL}/${countryName}/2023`
        downloadAnalytic('Full Report 2023', `Download Full Report ${countryName} 2023`)
        window.open(reportingRoundUrl)
    }
};

export const downloadSummaries = (year, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023, language) => {
    if (year === 2017 && participatedInSurvey2017 === 'YES') {
        const baselineSummaryUrl = `${DOWNLOAD_BASELINE_SUMMARY_URL}/${countryName}/${year}/${language}`;
        downloadAnalytic('Summaries 2017', `Download Summaries ${countryName} 2017`)
        window.open(baselineSummaryUrl);
    }
    if (year === 2020 && participatedInSurvey2020 === 'YES') {
        const reportingSummaryUrl = `${DOWNLOAD_REPORTING_SUMMARY_URL}/${countryName}/${year}/${language}`;
        downloadAnalytic('Summaries 2020', `Download Summaries ${countryName} 2020`)
        window.open(reportingSummaryUrl);
    }
    // make sure the download summary 2023 url
    if (year === 2023 && participatedInSurvey2023 === 'YES') {
        const reportingSummaryUrl = `${DOWNLOAD_REPORTING_SUMMARY_URL}/${countryName}/${year}/${language}`;
        downloadAnalytic('Summaries 2020', `Download Summaries ${countryName} 2023`)
        window.open(reportingSummaryUrl);
    }
};

export const downLoadFactsheet = (countryName) => {
    const isfactsheetExists = globalVar.CURRENT_AVAILABLE_FACTSHEETS.includes(countryName);
    if (isfactsheetExists) {
        const factsheetURL = `${DOWNLOAD_COUNTRY_FACTSHEETS_PILOTS_URL}/${countryName}`;
        downloadAnalytic('Factsheet', `Download Factsheet ${countryName}`)
        window.open(factsheetURL);
    }
};

export const downloadStakeholderConsultation = (year, countryName) => {
    downloadAnalytic('Stakeholder', `Download Stakeholder ${countryName} ${year}`)
    const stakeholderURL = `${STAKEHOLDER_URL}/${countryName}/${year}`;
    window.open(stakeholderURL);
}

export const downloadActionPlans = (year, countryName) => {
    const actionPlansURL = `${ACTION_PLANS_URL}/${countryName}/${year}`;
    downloadAnalytic('Action plans', `Download Action plans ${countryName} ${year}`)
    window.open(actionPlansURL);
}