import React, { useEffect } from 'react'
import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Breadcrumb from './components/breadcrumb/Breadcrumb';
import Home from './pages/home/Home'
import Map from './pages/map/Map'
import Country from './pages/country/Country'
import Publication from './pages/publication/Publication'
import Support from './pages/support/Support'
import About from './pages/about/About'
import Admin from './pages/admin/Admin'
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import TermOfServices from './pages/termOfServices/TermOfServices';
import ReactGA from 'react-ga';
import 'mapbox-gl/dist/mapbox-gl.css';
import Spinner from './components/spinner/Spinner';
import { useAppContext } from './context/AppContext';
import DataCollection from './pages/dataCollection/DataCollection';

const App = () => {

  const {
    state: { loading }
  } = useAppContext()
  const location = useLocation()

  const trackingId = 'UA-66550944-1';
  ReactGA.initialize(trackingId);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location])

  return (
    <div className="app">
      {location.pathname !== '/admin' && <Header />}
      <div className="app-container">
        {!(location.pathname === '/' || location.pathname === '/interactive-map') && location.pathname !== '/admin' && <Breadcrumb name={location.pathname} />}
        <Routes>
          <Route match="exact" path="/" element={<Home />} />
          <Route match="partial" path="/data-collection" element={<DataCollection />} />
          <Route match="partial" path="/interactive-map" element={<Map />} />
          <Route match="partial" path="/country-reports" element={<Country />} />
          <Route match="partial" path="/publications/:id" element={<Publication />} />
          <Route match="partial" path="/support-programme" element={<Support />} />
          <Route match="partial" path="/about" element={<About />} />
          <Route match="partial" path="/term-of-service" element={<TermOfServices />} />
          <Route match="partial" path="/admin" element={<Admin />} />
        </Routes>
      </div>
      {location.pathname !== '/interactive-map' && location.pathname !== '/admin' && <Footer />}
      {
        loading && <Spinner />
      }
    </div>
  )
}

export default App