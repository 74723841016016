import React from 'react'
import { Link } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { menus } from '../menu/Menu';

const Breadcrumb = ({ name }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontSize: 12,
            margin: '120px 20px 20px'
        }}>
            <Link to="/" style={{
                textDecoration: 'none',
                color: 'lightgray',
                marginRight: '10px'
            }}>
                Home
            </Link>
            <ArrowForwardIosIcon style={{ fontSize: 14, display: 'block', color: 'lightgray' }} />
            <span style={{
                color: '#00aeef',
                marginLeft: '10px'
            }}>
                {
                    name.includes('/publications') ? (
                        'Resource Library'
                    ) : (
                        menus.filter(item => item.path === name)[0]?.name
                    )
                }
            </span>
        </div>
    )
}

export default Breadcrumb