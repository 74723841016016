import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet';
import {
  Link,
  Stack,
  Box,
  Typography
} from '@mui/material';
import NewsCard from '../../components/card/NewsCard'
import NewsSlider from '../../components/news/NewsSlider';
import Card1 from '../../assets/home/cards/card1.png'
import Card2 from '../../assets/home/cards/card2.png'
import Card3 from '../../assets/home/cards/card3.png'
import { NavLink } from 'react-router-dom';

const cardData = [
  {
    title: 'Interactive Map',
    content:
      'Explore the global reporting results on SDG Indicator 6.5.1 via the interactive map.',
    link: '/interactive-map',
    img: Card1,
  },
  {
    title: 'Country reports',
    content:
      'See all country reports, summaries, and data here.',
    link: '/country-reports',
    img: Card2,
  },
  {
    title: 'IWRM Support Programme',
    content:
      'Find out what support is available to support IWRM reporting and implementation efforts in your country.',
    link: '/support-programme',
    img: Card3,
  },
];

const Home = () => {
  return (
    <Fragment>
      <Helmet>
        <title>IWRM Data Portal - Home</title>
        <meta name="description" content="Tracking global progress on implementation of Integrated Water Resources Management (IWRM) and Sustainable Development Goal (SDG) indicator 6.5.1." />
        <meta name="keywords" content="IWRM, SDG 6, SDG 6.5.1, water management, sustainable development" />
      </Helmet>
      <Box className="main-container">
        <Stack style={{
          width: '100%',
          height: '350px',
          backgroundImage: `url(${require('../../assets/home/slider/news2.png')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          marginTop: '100px',
          position: 'relative'
        }}>
          <Box style={{
            position: 'absolute',
            top: '20%',
            padding: '30px'
          }}>
            <Typography variant='h1' sx={{
              display: 'block',
              color: 'white',
              textTransform: 'uppercase',
              fontSize: '45px',
              fontWeight: 700
            }}>IWRM Data Portal</Typography>
            <Typography variant='body1' sx={{
              display: 'block',
              color: 'white',
              fontSize: '18px',
              fontWeight: 400
            }}>Tracking global progress on implementation of Integrated Water Resources Management (IWRM) and Sustainable Development Goal (SDG) indicator 6.5.1.</Typography>
          </Box>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{
            position: 'relative',
            marginTop: '-100px'
          }}
        >
          {
            cardData.map(item => {
              return (
                <NewsCard
                  key={item.title}
                  url={item.img}
                  title={item.title}
                  text={item.content}
                  link={item.link}
                />
              )
            })
          }
        </Stack>

        <Stack
          direction='column'
          justifyContent='center'
          sx={{
            width: '900px',
            margin: '60px auto 40px',
          }}
        >
          <Typography
            variant='h3'
            color='#09334B'
            fontSize={18}
            fontWeight={700}
            sx={{ marginBottom: 2 }}
          >
            What is the IWRM Data Portal?
          </Typography >
          <Typography variant='body2' color='black' sx={{ marginBottom: 1, fontSize: 14 }}>
            The IWRM Data Portal provides easy access to global, regional, and national status of implementation of Integrated Water Resources Management (IWRM). The portal is the official repository of SDG 6.5.1 data, with results from 191 countries across 3 reporting rounds (2017, 2020, 2023). It contains all country reports and results, associated analysis and reporting, and the underlying methodologies.
          </Typography>
          <Typography variant='body2' color='black' fontSize={14}>
            The IWRM Data Portal is maintained by <Link
              href="https://www.unepdhi.org/"
              target='_blank'
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                color: '#00aeef'
              }}
            >UNEP-DHI Centre on Water and Environment</Link>, in partnership with the <Link
              href="https://www.unep.org/"
              target='_blank'
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                color: '#00aeef'
              }}
            >United Nations Environment Programme (UNEP)</Link> and the <Link
              href="https://www.gwp.org/en/sdg6support/"
              target='_blank'
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                color: '#00aeef'
              }}
            >Global Water Partnership (GWP)</Link>. <NavLink to='/about'>Read more ...</NavLink>
          </Typography>
        </Stack>
        <NewsSlider />
      </Box>
    </Fragment>
  )
}

export default Home