import React, { useState, useEffect, useCallback, useRef } from 'react';
import MapGl, { Source, Layer } from 'react-map-gl';
import bbox from '@turf/bbox';
import centroid from '@turf/centroid';
import { useAppContext } from '../../context/AppContext';
import { MAPBOX_STYLE, MAPBOX_TOKEN } from '../../global-constanta';
import MajorLakes from '../../Major_Lakes.json';
import Fab from './Fab';
import Legend from './Legend';
import Modal from './Modal';
import { Box } from '@mui/material';
import globalVar from '../../global-constanta';
import { getCountryAnswers } from '../../utils/FetchFunction';
import {
    GetSDG651Scores,
    getBounds,
    getCombinedBounds,
    calculateCentroid
} from '../../utils/HelperFunction';
import PopUpOverlay from './PopUpOverlay';

const ReactMapGl = ({
    geoJsonArray,
    isInteractive,
    latestSections,
    surveyIndicator,
    getCountrySubGroupRaw,
    countrySubGroupRaw,
    countryAnswer,
    setCountryAnswer,
}) => {

    const mapRef = useRef()

    const {
        state: {
            viewport,
            initialViewport,
            showBaseMap,
            showModal,
            selectedLayer,
            countrySelected,
            selectedPopup,
            year,
            mapType,
            regionType,
            geographicRegionSelected,
            economicRegionSelected,
            regionCovered
        },
        action: {
            setViewport,
            setShowBaseMap,
            setShowModal,
            setSelectedLayer,
            setCountrySelected,
            setSelectedPopup,
            setRegionCovered
        }
    } = useAppContext()

    const [popupCoordinates, setPopupCoordinates] = useState(null)
    const [hoverInfo, setHoverInfo] = useState(null);
    const [cursor, setCursor] = useState('auto');

    const processFeatureClick = (feature) => {
        if (!feature) return;

        let bounds, countrySelected, centroidCoords;

        if (feature?.geoJsonShape) {
            bounds = getBounds(feature);
            countrySelected = feature.name === 'Taiwan' ? 'China' : feature.name;
            centroidCoords = calculateCentroid(feature);
        } else {
            const [minLng, minLat, maxLng, maxLat] = bbox(feature);
            bounds = [[minLng, minLat], [maxLng, maxLat]];
            countrySelected = feature.source;
            centroidCoords = centroid(feature._geometry).geometry.coordinates;
        }

        mapRef.current?.fitBounds(bounds, { padding: 40, duration: 1000 });
        setCountrySelected(countrySelected);
        processCountrySelection(countrySelected);
        setSelectedLayer(geoJsonArray.filter(item => item.name === countrySelected));
        setPopupCoordinates(centroidCoords);
        togglePopup();

    };

    const processCountrySelection = (countrySelected) => {
        if (isInteractive) {
            if (globalVar.DEFAULT_SURVEY_INDICATOR.includes(surveyIndicator)) {
                getCountryAnswers(countrySelected, year, setCountryAnswer);
            } else {
                getCountrySubGroupRaw(countrySelected);
            }
        }
    };

    const togglePopup = () => {
        setSelectedPopup(false);
        setTimeout(() => setSelectedPopup(true), 0);
    };

    const onLayerClick = (event) => {
        const feature = event.features[0];
        processFeatureClick(feature);
    };

    useEffect(() => {
        if (selectedLayer && popupCoordinates) {
            setSelectedPopup(false);
            setTimeout(() => setSelectedPopup(true), 0);
        }
    }, [selectedLayer, popupCoordinates]);

    useEffect(() => {
        if (countrySelected) {
            const feature = geoJsonArray.find(item => item.name === countrySelected);
            setSelectedLayer(null);
            setCountrySelected('')
            setSelectedPopup(false)

            if (feature ) {
                processFeatureClick(feature);
            }
        }
    }, [countrySelected, geoJsonArray, surveyIndicator, year]);

    let barChartData = { x: [], y: [], colors: [] };
    if (countryAnswer) {
        barChartData = GetSDG651Scores(countryAnswer, latestSections, true);
    }

    const onSelectRegion = () => {
        let regionArraySelected = [];
        let regionCovered = [];
    
        if (regionType === 'geographic' && geographicRegionSelected) {
            regionArraySelected = geoJsonArray.filter(res => res.geographic === geographicRegionSelected);
            regionCovered = geoJsonArray.filter(res => res.geographic !== geographicRegionSelected);
        } else if (regionType === 'economic' && economicRegionSelected) {
            regionArraySelected = geoJsonArray.filter(res => res.economic.includes(economicRegionSelected));
            regionCovered = geoJsonArray.filter(res => !res.economic.includes(economicRegionSelected));
        }
    
        if (regionArraySelected.length > 0) {
            setRegionCovered(regionCovered);
            const bounds = getCombinedBounds(regionArraySelected);
            mapRef.current?.fitBounds(bounds, { padding: 40, duration: 1000 });
        }
    };
    
    useEffect(() => {
        onSelectRegion();
    }, [mapType, regionType, geographicRegionSelected, economicRegionSelected]);
    

    const onHover = useCallback(event => {
        const {
            features,
            point: { x, y }
        } = event;
        const hoveredFeature = features && features[0];
        setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
    }, []);

    const onMouseEnter = useCallback(() => setCursor('pointer'), []);
    const onMouseLeave = useCallback(() => setCursor('auto'), []);

    return (
        <MapGl
            {...viewport}
            ref={mapRef}
            mapboxAccessToken={MAPBOX_TOKEN}
            mapStyle={MAPBOX_STYLE}
            interactiveLayerIds={geoJsonArray.map(geoJsonData => `geojson-layer-${geoJsonData.name}`)}
            onMove={(evt) => {
                setViewport(evt.viewState)
            }}
            attributionControl={false}
            onClick={onLayerClick}
            onMouseMove={onHover}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            cursor={cursor}
        >
            {showBaseMap && geoJsonArray && (
                geoJsonArray.map(geoJsonData => (
                    <Source
                        key={geoJsonData.name}
                        id={geoJsonData.name}
                        type="geojson"
                        data={geoJsonData.geoJsonShape}
                    >
                        <Layer
                            id={`geojson-layer-${geoJsonData.name}`}
                            type="fill"
                            paint={{
                                'fill-color': geoJsonData.color,
                                'fill-opacity': (hoverInfo && hoverInfo.feature && hoverInfo.feature.source === geoJsonData.name) || (selectedLayer && selectedLayer.some(layer => layer.name === geoJsonData.name)) ? 0.8 : 0.5,
                            }}
                        />
                    </Source>
                ))
            )}

            {/* MajorLakes layer */}
            {
                showBaseMap && geoJsonArray.length > 0 && (
                    <Source id="major-lakes" type="geojson" data={MajorLakes}>
                        {MajorLakes.geometries.map((geometry, index) => (
                            <Layer
                                key={index}
                                id={`major-lake-layer-${index}`}
                                type="fill"
                                paint={{
                                    'fill-color': '#D4E7F7',
                                }}
                                sourceLayer={geometry.type}
                            />
                        ))}
                    </Source>
                )
            }

            {showBaseMap && isInteractive && mapType === 'regions' && regionCovered.length > 0 && (
                regionCovered.map(geoJsonData => (
                    <Source
                        key={geoJsonData.name}
                        id={geoJsonData.name}
                        type="geojson"
                        data={geoJsonData.geoJsonShape}>
                        <Layer
                            id={`region-covered-layer-${geoJsonData.name}`}
                            type="fill"
                            paint={{
                                'fill-color': '#FFFFFF',
                                'fill-opacity': 0.8,
                            }}
                        />
                    </Source>
                ))
            )}

            {isInteractive && selectedPopup && popupCoordinates && (
                <PopUpOverlay
                    popupCoordinates={popupCoordinates}
                    surveyIndicator={surveyIndicator}
                    barChartData={barChartData}
                    countryAnswer={countryAnswer}
                    countrySubGroupRaw={countrySubGroupRaw}
                />
            )}

            <Box
                position='absolute'
                top={10}
                right={10}
                zIndex={1}
            >
                <Fab
                    onZoomIn={() => setViewport({ ...viewport, zoom: viewport.zoom + 1 })}
                    onZoomOut={() => setViewport({ ...viewport, zoom: viewport.zoom - 1 })}
                    onReset={() => {
                        setViewport({
                            ...initialViewport,
                            transitionDuration: 1000,
                        });
                        setSelectedLayer(null);
                        setSelectedPopup(null);
                    }}
                    onShowBaseMap={() => setShowBaseMap(!showBaseMap)}
                    onShowModal={() => setShowModal(true)}
                    showBaseMap={showBaseMap}
                />
            </Box>
            
            <Box
                position='absolute'
                bottom={10}
                right={10}
                zIndex={4}
            >
                <Legend isInteractive={isInteractive} />
            </Box>
            <Modal open={showModal} onClose={() => setShowModal(false)} />
        </MapGl>
    )
}

export default ReactMapGl