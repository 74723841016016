import ReactGA from 'react-ga';

export const downloadAnalytic = (action, label) => {
  ReactGA.event({
      category: 'Download',
      action: action,
      label: label,
    });
}

export const GAPush = (action, category, label) => {
  ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
}