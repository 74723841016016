import React from 'react'
import { Box, CircularProgress } from '@mui/material';

const Spinner = () => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 999
            }}
        >
            <CircularProgress style={{ color: '#00aeef' }} />
        </Box>
    )
}

export default Spinner