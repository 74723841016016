import React from 'react'
import { Fab, Tooltip } from '@mui/material';

const FabButton = ({ title, onClick, icon }) => {
    return (
        <Tooltip
            title={title}
            placement="bottom"
            sx={{ margin: '3px' }}
        >
            <Fab
                color="primary"
                aria-label="add"
                size="small"
                style={{ backgroundColor: '#00a4ec' }}
                onClick={onClick}
            >
                {icon}
            </Fab>
        </Tooltip>
    )
}

export default FabButton