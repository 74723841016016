import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { GET_QUESTIONS_URL, GET_COUNTRIES_SUBGROUP_RAW_URL, GET_COUNTRY_SUBGROUP_RAW_URL } from '../../api/IWRMServices';
import { useAppContext } from '../../context/AppContext';
import { Box } from '@mui/material';
import MapMenu from '../../components/deckgl/MapMenu';
import ReactMapGl from '../../components/deckgl/ReactMapGl';
import { urlEncode } from '../../utils/HelperFunction';

const Map = () => {
  const {
    state: { questions, sections, geoJsonArray, year },
    action: { setLoading, setQuestions, setSections, setGeoJsonArray }
  } = useAppContext();

  const [surveyIndicator, setSurveyIndicator] = useState('');
  const [finalScoreQuestion, setFinalScoreQuestion] = useState(null);
  const [countryAnswer, setCountryAnswer] = useState([]);
  const [countriesSubGroupRaw, setCountriesSubGroupRaw] = useState([]);
  const [countrySubGroupRaw, setCountrySubGroupRaw] = useState(null);

  const getQuestions = useCallback(async () => {
    setLoading(true);
    const questionsUrl = `${GET_QUESTIONS_URL}/${year}`;
    try {
      let response = await fetch(questionsUrl);
      let object = await response.json();
      const finalScoreQuestion = object[1]?.find(q => q.QuestionNumber === '6.5.1 Final Score');
      setQuestions(object[0]);
      setSections(object[1]);
      setFinalScoreQuestion(finalScoreQuestion);
      setSurveyIndicator(finalScoreQuestion.QuestionText);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [year, setLoading, setQuestions, setSections]);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const getCountriesSubGroupRaw = useCallback(async () => {
    setLoading(true);
    let encodeSurveyIndicator = urlEncode(surveyIndicator);
    const countriesUrl = `${GET_COUNTRIES_SUBGROUP_RAW_URL}/${encodeSurveyIndicator}/${year}/${false}`;
    try {
      let response = await fetch(countriesUrl);
      let object = await response.json();
      setCountriesSubGroupRaw(object);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [surveyIndicator, year, setLoading]);

  useEffect(() => {
    if (surveyIndicator === '') return;
    getCountriesSubGroupRaw();
  }, [surveyIndicator, getCountriesSubGroupRaw]);

  const getCountrySubGroupRaw = useCallback(async (country) => {
    let editedSurveyIndicator = urlEncode(surveyIndicator);
    const countriesUrl = `${GET_COUNTRY_SUBGROUP_RAW_URL}/${country}/${editedSurveyIndicator}/${year}`;
    try {
      let response = await fetch(countriesUrl);
      let object = await response.json();
      setCountrySubGroupRaw(object);
    } catch (error) {
      console.log(error);
    }
  }, [surveyIndicator, year]);

  useEffect(() => {
    if (countriesSubGroupRaw) {
      const newGeoJsonArray = [];
      countriesSubGroupRaw.forEach(item => {
        let geojson = '';
        if (item.GeoJsonShape) {
          try {
            geojson = JSON.parse(item.GeoJsonShape);
          } catch (error) {
            console.error(item.Name, error);
            console.log(item.GeoJsonShape);
          }
        }
        const tmp = {
          name: item.Name,
          color: item.Color,
          geoJsonShape: geojson,
          geographic: item.Region,
          economic: item.EconomicsZone,
        };
        newGeoJsonArray.push(tmp);
      });
      setGeoJsonArray(newGeoJsonArray);
    }
  }, [countriesSubGroupRaw, setGeoJsonArray]);

  return (
    <Fragment>
      <Helmet>
        <title>IWRM Data Portal - Interactive Map</title>
        <meta name="description" content="SDG 6.5.1 reporting result showing in interactive map." />
        <meta name="keywords" content="IWRM, SDG 6, SDG 6.5.1, water management, sustainable development, interactive map IWRM, interactive map SDG 6, interactive map SDG 6.5.1" />
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 100px)',
          marginTop: '100px',
          marginBottom: '0',
          position: 'relative',
          overflowY: 'hidden',
        }}
      >
        <ReactMapGl
          geoJsonArray={geoJsonArray}
          isInteractive={true}
          latestSections={sections}
          surveyIndicator={surveyIndicator}
          getCountrySubGroupRaw={getCountrySubGroupRaw}
          countrySubGroupRaw={countrySubGroupRaw}
          countryAnswer={countryAnswer}
          setCountryAnswer={setCountryAnswer}
        />
        <Box
          position='absolute'
          top={10}
          left={10}
          zIndex={1}
        >
          <MapMenu
            geoJsonArray={geoJsonArray}
            sections={sections}
            questions={questions}
            finalScoreQuestion={finalScoreQuestion}
            surveyIndicator={surveyIndicator}
            setSurveyIndicator={setSurveyIndicator}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Map;
