import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Stack, Typography } from '@mui/material';
import { menus } from '../menu/Menu'

const Footer = () => {
  return (
    <Stack
      flexDirection='column'
      width='100%'
      justifyContent='center'
      alignItems='center'
    >
      <Stack
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
        width='100%'
        height='100px'
        backgroundColor='#002437'
      >
        {
          menus.filter(item => item.path !== '#').map(menu => (
            <NavLink
              // exact
              to={menu.path}
              key={menu.id}
              style={{
                display: 'block',
                fontSize: 12,
                fontWeight: 400,
                color: 'white',
                margin: '0px 8px',
                textDecoration: 'none'
              }}
            >{menu.name}</NavLink>
          ))
        }
      </Stack>
      <Stack
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
        width='100%'
        height='50px'
        backgroundColor='#00324e'
        color='white'
        fontSize={10}
      >
        <Typography fontSize={9}>IWRM Data Portal is a service from <Link
          href='https://www.unepdhi.org/'
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none',
            color: '#00a4ec',
            fontSize: 10
          }}
        >UNEP-DHI Centre</Link> | <NavLink
          // exact
          to="/term-of-service"
          style={{
            textDecoration: 'none',
            color: '#00a4ec',
            fontSize: 10
          }}
        >Terms of Service</NavLink>.</Typography>
      </Stack>
    </Stack >
  )
}

export default Footer