import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext();

function AppProvider({ children }) {

    const [loading, setLoading] = useState(false)
    const [overview, setOverview] = useState([])
    const [submission2020Status, setSubmission2020Status] = useState([])
    const [questions, setQuestions] = useState([])
    const [sections, setSections] = useState([])
    const [initialViewport, setInitialViewport] = useState({
        longitude: 62.23258456387173,
        latitude: 24.214013864933438,
        zoom: 2,
        pitch: 0,
        bearing: 0,
        maxPitch: 60,
    })
    const [viewport, setViewport] = useState(initialViewport)
    const [year, setYear] = useState(2023);
    const [geoJsonArray, setGeoJsonArray] = useState([]);
    const [isGeoJsonArrayLoaded, setIsGeoJsonArrayLoaded] = useState(false)
    const [showBaseMap, setShowBaseMap] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [countrySelected, setCountrySelected] = useState('')
    const [mapType, setMapType] = useState('countries')
    const [regionType, setRegionType] = useState('geographic')
    const [geographicRegionSelected, setGeographicRegionSelected] = useState('')
    const [economicRegionSelected, setEconomicRegionSelected] = useState('')
    const [regionCovered, setRegionCovered] = useState([])
    const [selectedLayer, setSelectedLayer] = useState(null)
    const [selectedPopup, setSelectedPopup] = useState(false)

    return (
        <AppContext.Provider value={{
            state: {
                loading,
                overview,
                submission2020Status,
                questions,
                sections,
                initialViewport,
                viewport,
                year,
                geoJsonArray,
                isGeoJsonArrayLoaded,
                showBaseMap,
                showModal,
                countrySelected,
                mapType,
                regionType,
                geographicRegionSelected,
                economicRegionSelected,
                regionCovered,
                selectedLayer,
                selectedPopup
            },
            action: {
                setLoading,
                setOverview,
                setSubmission2020Status,
                setQuestions,
                setSections,
                setInitialViewport,
                setViewport,
                setYear,
                setGeoJsonArray,
                setIsGeoJsonArrayLoaded,
                setShowBaseMap,
                setShowModal,
                setCountrySelected,
                setMapType,
                setRegionType,
                setGeographicRegionSelected,
                setEconomicRegionSelected,
                setRegionCovered,
                setSelectedLayer,
                setSelectedPopup
            }
        }}>
            {children}
        </AppContext.Provider>
    );
}

function useAppContext() {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
}

export { AppProvider, useAppContext };
