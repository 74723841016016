import React from 'react'
import {useLocation} from "react-router-dom";
import { Box, Stack } from '@mui/material';
import { Visibility, VisibilityOff, Info, SettingsBackupRestore, Add, Remove } from '@mui/icons-material';
import FabButton from '../button/FabButton';

const Fab = ({ onZoomIn, onZoomOut, onReset, onShowBaseMap, onShowModal, showBaseMap }) => {
    const location = useLocation();
    const rowActions = [
        {
            title: showBaseMap ? 'Hide basemap' : 'Show basemap',
            onClick: onShowBaseMap,
            icon: showBaseMap ? <VisibilityOff /> : <Visibility />
        },
        {
            title: 'Reset',
            onClick: onReset,
            icon: <SettingsBackupRestore />
        },
        {
            title: 'Info',
            onClick: onShowModal,
            icon: <Info />
        }
    ]

    const columnActions = [
        {
            title: 'Zoom In',
            onClick: onZoomIn,
            icon: <Add />
        },
        {
            title: 'Zoom Out',
            onClick: onZoomOut,
            icon: <Remove />
        }
    ]

    return (
        <Box>
            <Stack flexDirection='row'>
                {
                    rowActions.map((item, idx) => item.title !== 'Info' ? (
                        <FabButton
                            key={idx}
                            title={item.title}
                            onClick={item.onClick}
                            icon={item.icon}
                        />
                    ) : location.pathname !== '/data-collection' ? (
                        <FabButton
                            key={idx}
                            title={item.title}
                            onClick={item.onClick}
                            icon={item.icon}
                        />
                    ) : '' )
                }
            </Stack>
            <Stack flexDirection='column' alignItems='flex-end' marginTop={2}>
                {
                    columnActions.map((item, idx) => (
                        <FabButton
                            key={idx}
                            title={item.title}
                            onClick={item.onClick}
                            icon={item.icon}
                        />
                    ))
                }
            </Stack>
        </Box>
    )
}

export default Fab