import React from 'react'
import { NavLink } from 'react-router-dom';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import Box from '@mui/material/Box';

import newsImage1 from '../../assets/home/slider/news1.png'
import newsImage2 from '../../assets/home/slider/news2.png'
import newsImage3 from '../../assets/home/slider/news3.png'
import newsImage4 from '../../assets/home/slider/news4.png'
import newsImage5 from '../../assets/home/slider/news5.png'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const newsData = [
  {
    id: 0,
    title: '2023 data is now live!',
    content:
      'The third round of reporting on SDG 6.5.1 - on the implementation of Integrated Water Resources Management (IWRM) - took place in 2023. 180 countries submitted data and you can now explore their results and surveys via the global interactive map and the country reports pages. Data analysis of the results is currently underway, and the global progress report is expected to be launched in August 2024.',
    img: newsImage3,
    button: [
      {
        link: '/interactive-map',
        buttonText: 'Interactive map'
      },
      {
        link: '/country-reports',
        buttonText: 'Country reports'
      },
    ]
  },
  {
    id: 1,
    title: 'Tools and approaches for gender mainstreaming in water resources management',
    content:
      'SDG 6.5.1 reporting shows that gender mainstreaming in water resources management is among the least advanced aspects of IWRM implementation. The SDG 6 IWRM Support Programme held a successful learning exchange and launched a report on the topic. The report shares country experiences from the learning exchange and the 651 survey and provides practical approaches to improving gender mainstreaming in water management.',
    img: newsImage1,
    button: [
      {
        link: '/publications/thematic',
        buttonText: 'View Report'
      }
    ]
  },
  {
    id: 2,
    title: 'More than 60 countries supported with stakeholder consultations in 2023.',
    content:
      '67 countries were assisted through the SDG 6 IWRM Support Programme to hold broad stakeholder consultation processes for reporting on 6.5.1 in 2023, involving an average of 42 stakeholders per country. Explore options for getting support to formulate responses, and accessing finance to implement solutions, on the Support Programme page.',
    img: newsImage2,
    button: [
      {
        link: '/support-programme',
        buttonText: 'Support Programme'
      }
    ]
  },
  {
    id: 3,
    title: 'Explore IWRM tools and resources with the IWRM Action Hub!',
    content:
      'The IWRM Action Hub is a global knowledge platform that supports and connects actors in their efforts to implement integrated water resources management (IWRM). The IWRM Action Hub has a vast collection of IWRM Tools, case studies, technical references, and learning materials. It also has a connect function where users can directly interact with each other or via one of the 16 online communities of practice. The Hub is useful for anyone interested in adopting better practices for the management of water or learning more about how to improve water management at a local, national, regional or global level. The platform has over 3000 registered users as part of its IWRM Action Network.',
    img: newsImage4,
    button: [
      {
        link: 'https://iwrmactionhub.org/',
        buttonText: 'IWRM Action Hub'
      },
      {
        link: 'https://iwrmactionhub.org/connect/communities',
        buttonText: 'Communities of practice'
      },
      {
        link: 'https://iwrmactionhub.org/connect/network',
        buttonText: 'IWRM Action Network'
      }
    ]
  },
  {
    id: 4,
    title: '2024 Global progress report on SDG 6.5.1 is available!',
    content:
      'This new report gives an overview of the status of Integrated Water Resource Management (IWRM) around the world as of 2023. Findings show global progress towards IWRM implementation has increased from 49% in 2017 to 57% in 2023. But the world is not on track to reach the 2030 target and will not achieve sustainable water management until at least 2049 – 25 years from now. FRead the full report to learn more!',
    img: newsImage5,
    button: [
      {
        link: '/publications/global',
        buttonText: 'Full Report'
      }
    ]
  },
];

const NextArrow = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        width: '30px',
        height: '30px',
        position: 'relative',
        top: '-235px',
        right: '-95%',
        zIndex: 1
      }}
    >
      <ChevronRight sx={{ color: '#00aeef', cursor: 'pointer', fontSize: '54px' }} />
    </div>
  );
};

const PrevArrow = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        width: '30px',
        height: '30px',
        position: 'relative',
        bottom: '-200px',
        zIndex: 1
      }}
    >
      <ChevronLeft sx={{ color: '#00aeef', cursor: 'pointer', fontSize: '54px' }} />
    </div>
  );
};

const NewSlider = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 4000
  };

  return (
    <Box sx={{ position: 'relative', marginBottom: '-70px' }}>
      <Slider {...settings}>
        {
          newsData?.map(news => {
            if (news.id !== 1){
              return(
              <div key={news.title}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '50%', height: '400px' }}>
                    <img
                      src={news.img}
                      alt={news.title}
                      style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                    />
                  </div>
                  <div style={{
                    width: '50%',
                    backgroundColor: '#DBE4E9',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: '30px'
                  }}>
                    <h4
                      style={{
                        color: '#09334B',
                        fontWeight: 700,
                        fontSize: 24,
                        lineHeight: '30px'
                      }}>{news.title}</h4>
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: '#09334B',
                        marginRight: 30,
                      }}
                    >{news.content}</p>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      {
                        news.button.map(item => {
                          const isExternal = item.link.startsWith('http://') || item.link.startsWith('https://');
                            return isExternal ? (
                              <a
                                key={item.buttonText}
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: 'none',
                                  backgroundColor: '#00aeef',
                                  color: 'white',
                                  padding: '10px 12px',
                                  borderRadius: '5px',
                                  fontSize: 12
                                }}
                              >
                                {item.buttonText}
                              </a>
                            ) : (
                              <NavLink
                                key={item.buttonText}
                                to={item.link}
                                // exact
                                style={{
                                  textDecoration: 'none',
                                  backgroundColor: '#00aeef',
                                  color: 'white',
                                  padding: '10px 12px',
                                  borderRadius: '5px',
                                  fontSize: 12
                                }}
                              >
                                {item.buttonText}
                              </NavLink>
                            );
                          })
                      }
                    </Box>
                  </div>
                </div>
              </div>
              );
            } else {
              return null
            };
          })
        }
      </Slider>
    </Box>
  )
}

export default NewSlider