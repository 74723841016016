import React from 'react'
import { Popup } from 'react-map-gl';
import { useAppContext } from '../../context/AppContext';
import { Box, Typography, Divider, Skeleton, Stack } from '@mui/material';
import Plot from 'react-plotly.js';
import globalVar from '../../global-constanta';
import { removeAfterParenthesis } from '../../utils/HelperFunction';

const PopUpOverlay = ({
    popupCoordinates,
    surveyIndicator, 
    barChartData, 
    countryAnswer, 
    countrySubGroupRaw
}) => {
    
    const {
        state: {
            selectedLayer,
            countrySelected,
            year
        },
        action: {
            setSelectedLayer,
            setCountrySelected,
            setSelectedPopup
        }
    } = useAppContext()

    console.log('selectedLayer: ', selectedLayer)
    console.log('countrySubGroupRaw: ', countrySubGroupRaw)

    return (
        <Popup
            latitude={popupCoordinates && popupCoordinates[1]}
            longitude={popupCoordinates && popupCoordinates[0]}
            onClose={() => {
                setSelectedLayer(null);
                setCountrySelected('')
                setSelectedPopup(false)
            }}
            focusAfterOpen
            closeButton={true}
            anchor='bottom'
            className='custom-popup'
        >
            <div style={{ padding: '5px', borderRadius: '4px', backgroundColor: 'white' }}>
                {selectedLayer && globalVar.DEFAULT_SURVEY_INDICATOR.includes(surveyIndicator) ? (
                    selectedLayer.map((item, index) => (
                        <div key={index}>
                            <Typography variant="h6" textAlign={'center'}>{item.name}</Typography>
                            <Divider variant="middle" />
                            <Typography fontSize={11} textAlign={'center'}>SDG 6.5.1 {year} Reporting result</Typography>
                            {barChartData &&
                                countryAnswer &&
                                countryAnswer[0].Country.Name ===
                                countrySelected ? (
                                <React.Fragment>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div>
                                            <Plot
                                                data={[
                                                    {
                                                        type: 'bar',
                                                        orientation: 'h',
                                                        x: barChartData.x,
                                                        y: barChartData.y,
                                                        text: barChartData.x,
                                                        textposition: 'auto',
                                                        hoverinfo: 'none',
                                                        marker: {
                                                            color: barChartData.colors,
                                                        },
                                                    },
                                                ]}
                                                layout={{
                                                    autosize: true,
                                                    width: 400,
                                                    height: 180,
                                                    margin: {
                                                        l: 10,
                                                        r: 10,
                                                        b: 30,
                                                        t: 30,
                                                        pad: 4,
                                                    },
                                                    // title: '',
                                                    xaxis: {
                                                        automargin: true,
                                                        range: [0, 100],
                                                    },
                                                    yaxis: {
                                                        automargin: true,
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: 100, flexDirection: 'column' }}>
                                            {globalVar.SDG_LEGEND.map(legendItem => (
                                                <div key={legendItem.name} style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
                                                    <div style={{ width: '8px', height: '8px', backgroundColor: legendItem.color, marginRight: '5px' }}></div>
                                                    <Typography style={{ fontSize: '10px' }}>{legendItem.name.replace(/\(.*?\)/, '')}</Typography>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <Skeleton variant="rect" width={450} height={180} />
                            )}
                        </div>
                    ))
                ) : (
                    selectedLayer?.map((layer, index) => (
                        <div key={index}>
                            <Typography variant="h6" textAlign={'center'}>{layer.name}</Typography>
                            <Divider variant="middle" />
                            <Typography fontSize={12} textAlign={'center'}>{surveyIndicator}</Typography>
                            {
                                countrySubGroupRaw !== null ? (
                                    globalVar.SDG_LEGEND.filter(res => countrySubGroupRaw >= res.min && countrySubGroupRaw <= res.max).map(item => (
                                        <Stack
                                            flexDirection='row'
                                            justifyContent='center'
                                            alignItems='center'
                                            padding='2px 5px'
                                            gap='5px'
                                            key={item.name}
                                        >
                                            <Box sx={{
                                                width: 10,
                                                height: 10,
                                                borderRadius: '50%',
                                                backgroundColor: item.color,
                                                marginRight: 1
                                            }} />
                                            <Typography fontSize={11}>{countrySubGroupRaw === -1 ? "" : countrySubGroupRaw}</Typography>
                                            <Typography fontSize={11}>{removeAfterParenthesis(item.name)}</Typography>
                                        </Stack>
                                    ))
                                ) : (
                                    <Skeleton variant="rect" width={200} height={30} />
                                )
                            }
                        </div>
                    ))
                )}
            </div>
        </Popup>
    )
}

export default PopUpOverlay