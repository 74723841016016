import React, { useState } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Typography,
    Tabs,
    Tab,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import globalVar from '../../global-constanta'
import {
    downloadFullReport,
    downloadSummaries,
    downLoadFactsheet
} from '../../utils/DownloadFunction';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const DownloadConfiguration = ({
    countryName,
    setIsShowDownloadConfiguration,
    participatedInSurvey2017,
    participatedInSurvey2020,
    participatedInSurvey2023,
    currentAvailableFactSheets,
}) => {
    const [value, setValue] = useState(0);
    const [year, setYear] = useState(2017);
    const [isDownloadFullReport2017, setIsDownloadFullReport2017] = useState(
        false,
    );
    const [isDownloadFullReport2020, setIsDownloadFullReport2020] = useState(
        false,
    );
    const [isDownloadFullReport2023, setIsDownloadFullReport2023] = useState(
        false,
    );
    const [languagesSelected2017, setLanguagesSelected2017] = useState([]);
    const [languagesSelected2020, setLanguagesSelected2020] = useState([]);
    const [languagesSelected2023, setLanguagesSelected2023] = useState([]);
    const [isDownloadFactsheetReport, setIsDownloadFactsheetReport] = useState(
        false,
    );
    const [
        isDownloadStakeholderWorkshopReport,
        setIsDownloadStakeholderWorkshopReport,
    ] = useState(false);
    const [
        isDownloadNationalIWRMActionPlan,
        setIsDownloadNationalIWRMActionPlan,
    ] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleLanguageChange = e => {
        const { value } = e.target;
        const index2017 = languagesSelected2017.indexOf(value);
        const index2020 = languagesSelected2020.indexOf(value);
        const index2023 = languagesSelected2023.indexOf(value);
        if (year === 2017) {
            if (index2017 === -1) {
                setLanguagesSelected2017([...languagesSelected2017, value]);
            } else {
                setLanguagesSelected2017(
                    languagesSelected2017.filter(language => language !== value),
                );
            }
        }
        if (year === 2020) {
            if (index2020 === -1) {
                setLanguagesSelected2020([...languagesSelected2020, value]);
            } else {
                setLanguagesSelected2020(
                    languagesSelected2020.filter(language => language !== value),
                );
            }
        }
        if (year === 2023) {
            if (index2023 === -1) {
                setLanguagesSelected2023([...languagesSelected2023, value]);
            } else {
                setLanguagesSelected2023(
                    languagesSelected2023.filter(language => language !== value),
                );
            }
        }
    };

    const handleDownloadAll = () => {
        if (isDownloadFullReport2017 || isDownloadFullReport2020 || isDownloadFullReport2023) {
            if (isDownloadFullReport2017) {
                downloadFullReport(2017, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023)
            }
            if (isDownloadFullReport2020) {
                downloadFullReport(2020, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023)
            }
            if (isDownloadFullReport2023) {
                downloadFullReport(2023, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023)
            }
        }
        if (languagesSelected2017.length > 0 || languagesSelected2020.length > 0 || languagesSelected2023.length > 0) {
            if (languagesSelected2017.length > 0) {
                downloadSummaries(2017, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023, languagesSelected2017.join(','))
            }
            if (languagesSelected2020.length > 0) {
                downloadSummaries(2020, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023, languagesSelected2020.join(','))
            }
            if (languagesSelected2023.length > 0) {
                downloadSummaries(2023, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023, languagesSelected2023.join(','))
            }
        }
        
        if (isDownloadFactsheetReport) {
            downLoadFactsheet(countryName);
        }
        setIsShowDownloadConfiguration(false);
    };

    const handleDownloadReportChange = () => {
        if (year === 2017) {
            if (participatedInSurvey2017 === 'YES') {
                setIsDownloadFullReport2017(!isDownloadFullReport2017);
            }
        } else if (year === 2020) {
            if (participatedInSurvey2020 === 'YES') {
                setIsDownloadFullReport2020(!isDownloadFullReport2020);
            }
        } else if (year === 2023) {
            if (participatedInSurvey2023 === 'YES') {
                setIsDownloadFullReport2023(!isDownloadFullReport2023);
            }
        }
    };

    return (
        <div style={{ zIndex: 1000, position: 'relative' }}>
            <Backdrop sx={{ color: '#fff', zIndex: 1000 }} open>
                <Box
                    style={{
                        width: 500,
                        backgroundColor: '#FFF',
                        padding: 10,
                        borderRadius: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'stretch',
                    }}
                >
                    <Box>
                        <Typography
                            variant="h5"
                            component="span"
                            style={{ fontSize: 14, fontWeight: 700 }}
                        >
                            Select documents for download
                        </Typography>
                    </Box>
                    <Box>
                        <Box>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="Download Configuration"
                                indicatorColor="primary"
                            >
                                <Tab
                                    label="2017"
                                    {...a11yProps(0)}
                                    onClick={() => setYear(2017)}
                                />
                                <Tab
                                    label="2020"
                                    {...a11yProps(1)}
                                    onClick={() => setYear(2020)}
                                />
                                <Tab
                                    label="2023"
                                    {...a11yProps(2)}
                                    onClick={() => setYear(2023)}
                                />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Box>
                                <Box style={{ marginBottom: 10 }}>
                                    <Typography
                                        variant="subtitle1"
                                        component="span"
                                        style={{ fontSize: 12, fontWeight: 600, color: 'black' }}
                                    >
                                        IWRM survey (SDG 6.5.1)
                                    </Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadFullReport2017}
                                                    color="primary"
                                                    onChange={handleDownloadReportChange}
                                                    disabled={!(participatedInSurvey2017 === 'YES')}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    IWRM survey (SDG 6.5.1) 2017
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="subtitle1"
                                        component="span"
                                        style={{ fontSize: 12, fontWeight: 600, color: 'black' }}
                                    >
                                        Country summary (6.5.1)
                                    </Typography>
                                    <FormGroup
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {globalVar.AVAILABLE_LANGUAGES.map(language => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={languagesSelected2017.includes(language)}
                                                        onChange={handleLanguageChange}
                                                        disabled={!(participatedInSurvey2017 === 'YES')}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        component="a"
                                                        style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                    >
                                                        {language}
                                                    </Typography>
                                                }
                                                value={language}
                                                key={language}
                                            />
                                        ))}
                                    </FormGroup>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="subtitle1"
                                        component="span"
                                        style={{ fontSize: 12, fontWeight: 600, color: 'black' }}
                                    >
                                        Other reports
                                    </Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadFactsheetReport}
                                                    disabled={
                                                        !(
                                                            currentAvailableFactSheets.filter(
                                                                item => item === countryName,
                                                            ).length > 0
                                                        )
                                                    }
                                                    color="primary"
                                                    onChange={() =>
                                                        setIsDownloadFactsheetReport(
                                                            !isDownloadFactsheetReport,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    Country IWRM factsheet
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadStakeholderWorkshopReport}
                                                    disabled
                                                    color="primary"
                                                    onChange={() =>
                                                        setIsDownloadStakeholderWorkshopReport(
                                                            !isDownloadStakeholderWorkshopReport,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    Stakeholder workshop report
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadNationalIWRMActionPlan}
                                                    disabled
                                                    color="primary"
                                                    onChange={() =>
                                                        setIsDownloadNationalIWRMActionPlan(
                                                            !isDownloadNationalIWRMActionPlan,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    National IWRM action plan
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Box>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Box>
                                <Box style={{ marginBottom: 10 }}>
                                    <Typography
                                        variant="subtitle1"
                                        component="span"
                                        style={{ fontSize: 12, fontWeight: 600, color: 'black' }}
                                    >
                                        IWRM survey (SDG 6.5.1)
                                    </Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadFullReport2020}
                                                    color="primary"
                                                    onChange={handleDownloadReportChange}
                                                    disabled={!(participatedInSurvey2020 === 'YES')}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    IWRM survey (SDG 6.5.1) 2020
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="subtitle1"
                                        component="span"
                                        style={{ fontSize: 12, fontWeight: 600, color: 'black' }}
                                    >
                                        Country summary (6.5.1)
                                    </Typography>
                                    <FormGroup
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {globalVar.AVAILABLE_LANGUAGES.map(language => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={languagesSelected2020.includes(language)}
                                                        onChange={handleLanguageChange}
                                                        disabled={!(participatedInSurvey2020 === 'YES')}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        component="a"
                                                        style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                    >
                                                        {language}
                                                    </Typography>
                                                }
                                                value={language}
                                                key={language}
                                            />
                                        ))}
                                    </FormGroup>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="subtitle1"
                                        component="span"
                                        style={{ fontSize: 12, fontWeight: 600, color: 'black' }}
                                    >
                                        Other reports
                                    </Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadFactsheetReport}
                                                    disabled={
                                                        !(
                                                            currentAvailableFactSheets.filter(
                                                                item => item === countryName,
                                                            ).length > 0
                                                        )
                                                    }
                                                    color="primary"
                                                    onChange={() =>
                                                        setIsDownloadFactsheetReport(
                                                            !isDownloadFactsheetReport,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    Country IWRM factsheet
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadStakeholderWorkshopReport}
                                                    disabled
                                                    color="primary"
                                                    onChange={() =>
                                                        setIsDownloadStakeholderWorkshopReport(
                                                            !isDownloadStakeholderWorkshopReport,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    Stakeholder workshop report
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadNationalIWRMActionPlan}
                                                    disabled
                                                    color="primary"
                                                    onChange={() =>
                                                        setIsDownloadNationalIWRMActionPlan(
                                                            !isDownloadNationalIWRMActionPlan,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    National IWRM action plan
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Box>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Box>
                                <Box style={{ marginBottom: 10 }}>
                                    <Typography
                                        variant="subtitle1"
                                        component="span"
                                        style={{ fontSize: 12, fontWeight: 600, color: 'black' }}
                                    >
                                        IWRM survey (SDG 6.5.1)
                                    </Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadFullReport2023}
                                                    color="primary"
                                                    onChange={handleDownloadReportChange}
                                                    disabled={!(participatedInSurvey2023 === 'YES')}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    IWRM survey (SDG 6.5.1) 2023
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="subtitle1"
                                        component="span"
                                        style={{ fontSize: 12, fontWeight: 600, color: 'black' }}
                                    >
                                        Country summary (6.5.1)
                                    </Typography>
                                    <FormGroup
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {globalVar.AVAILABLE_LANGUAGES.map(language => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={languagesSelected2023.includes(language)}
                                                        onChange={handleLanguageChange}
                                                        disabled={!(participatedInSurvey2023 === 'YES')}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        component="a"
                                                        style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                    >
                                                        {language}
                                                    </Typography>
                                                }
                                                value={language}
                                                key={language}
                                            />
                                        ))}
                                    </FormGroup>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="subtitle1"
                                        component="span"
                                        style={{ fontSize: 12, fontWeight: 600, color: 'black' }}
                                    >
                                        Other reports
                                    </Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadStakeholderWorkshopReport}
                                                    disabled
                                                    color="primary"
                                                    onChange={() =>
                                                        setIsDownloadStakeholderWorkshopReport(
                                                            !isDownloadStakeholderWorkshopReport,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    Stakeholder consultation report 
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadNationalIWRMActionPlan}
                                                    disabled
                                                    color="primary"
                                                    onChange={() =>
                                                        setIsDownloadNationalIWRMActionPlan(
                                                            !isDownloadNationalIWRMActionPlan,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    National IWRM action plan
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDownloadFactsheetReport}
                                                    disabled={
                                                        !(
                                                            currentAvailableFactSheets.filter(
                                                                item => item === countryName,
                                                            ).length > 0
                                                        )
                                                    }
                                                    color="primary"
                                                    onChange={() =>
                                                        setIsDownloadFactsheetReport(
                                                            !isDownloadFactsheetReport,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="a"
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#00aeef' }}
                                                >
                                                    Country IWRM factsheet
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Box>
                            </Box>
                        </TabPanel>
                    </Box>
                    <Box
                        style={{
                            width: '100%',
                            backgoundColor: 'yellow',
                            textAlign: 'right',
                            padding: 10,
                        }}
                    >
                        <Button
                            variant="text"
                            onClick={() => setIsShowDownloadConfiguration(false)}
                        >
                            <Typography
                                variant="caption"
                                component="a"
                                style={{ fontWeight: 700 }}
                            >
                                Cancel
                            </Typography>
                        </Button>
                        {year === 2017 ? (
                            participatedInSurvey2017 === 'YES' ? (
                                isDownloadFullReport2017 ||
                                    isDownloadFullReport2020 ||
                                    isDownloadFactsheetReport ||
                                    languagesSelected2017.length > 0 ||
                                    languagesSelected2020.length > 0 ? (
                                    <Button variant="text" onClick={() => handleDownloadAll()}>
                                        <Typography
                                            variant="caption"
                                            component="a"
                                            style={{ fontWeight: 700 }}
                                        >
                                            Download Selected
                                        </Typography>
                                    </Button>
                                ) : (
                                    <Button variant="text" disabled>
                                        <Typography
                                            variant="caption"
                                            component="span"
                                            style={{ fontWeight: 700 }}
                                        >
                                            Download Selected
                                        </Typography>
                                    </Button>
                                )
                            ) : (
                                <Button variant="text" disabled>
                                    <Typography
                                        variant="caption"
                                        component="span"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Download Selected
                                    </Typography>
                                </Button>
                            )
                        ) : participatedInSurvey2020 === 'YES' ? (
                            isDownloadFullReport2017 ||
                                isDownloadFullReport2020 ||
                                isDownloadFactsheetReport ||
                                languagesSelected2017.length > 0 ||
                                languagesSelected2020.length > 0 ? (
                                <Button variant="text" onClick={() => handleDownloadAll()}>
                                    <Typography
                                        variant="caption"
                                        component="a"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Download Selected
                                    </Typography>
                                </Button>
                            ) : (
                                <Button variant="text" disabled>
                                    <Typography
                                        variant="caption"
                                        component="span"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Download Selected
                                    </Typography>
                                </Button>
                            )
                        ) : (
                            <Button variant="text" disabled>
                                <Typography
                                    variant="caption"
                                    component="span"
                                    style={{ fontWeight: 700 }}
                                >
                                    Download Selected
                                </Typography>
                            </Button>
                        )}
                    </Box>
                </Box>
            </Backdrop>
        </div>
    );
};

export default DownloadConfiguration;