import React from 'react'
import {
    Stack,
    Box,
    Grid,
    Typography,
    Button
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import globalVar from '../../global-constanta';
import {
    downloadFullReport,
    downloadSummaries,
    downLoadFactsheet,
    downloadStakeholderConsultation,
    downloadActionPlans
} from '../../utils/DownloadFunction'

const ReportSdg = ({
    year,
    countryName,
    setCountryName,
    setIsShowDownloadConfiguration,
    participatedInSurvey2017,
    participatedInSurvey2020,
    participatedInSurvey2023,
    setParticipatedInSurvey2017,
    setParticipatedInSurvey2020,
    setParticipatedInSurvey2023,
}) => {

    let currentAvailableFactSheets = globalVar.CURRENT_AVAILABLE_FACTSHEETS
    let currentAvailableStakeholder2020 = globalVar.CURRENT_AVAILABLE_STAKEHOLDER_2020
    let currentAvailableStakeholder2023 = globalVar.CURRENT_AVAILABLE_STAKEHOLDER_2023
    let currentAvailableActionPlans2017 = globalVar.CURRENT_AVAILABLE_ACTION_PLAN_2017
    let currentAvailableActionPlans2020 = globalVar.CURRENT_AVAILABLE_ACTION_PLAN_2020
    let currentAvailableActionPlans2023 = globalVar.CURRENT_AVAILABLE_ACTION_PLAN_2023

    const handleAllReports = () => {
        setCountryName(countryName)
        setIsShowDownloadConfiguration(true);
        setParticipatedInSurvey2017(participatedInSurvey2017);
        setParticipatedInSurvey2020(participatedInSurvey2020);
        setParticipatedInSurvey2023(participatedInSurvey2023);
    };

    let isStakeholderReportAvailable = false;
    if (year === 2020 && currentAvailableStakeholder2020.includes(countryName)) {
        isStakeholderReportAvailable = true;
    } else if (year === 2023 && currentAvailableStakeholder2023.some(item => item.country === countryName)) {
        isStakeholderReportAvailable = true;
    }

    let isActionPlansAvailable = false;
    if (year === 2017 && currentAvailableActionPlans2017.includes(countryName)) {
        isActionPlansAvailable = true;
    } else if (year === 2020 && currentAvailableActionPlans2020.includes(countryName)) {
        isActionPlansAvailable = true;
    } else if (year === 2023 && currentAvailableActionPlans2023.includes(countryName)) {
        isActionPlansAvailable = true;
    }

    return (
        <Grid
            container
            style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <Button
                onClick={handleAllReports}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 2,
                    width: 'fit-content'
                }}>
                <GetAppIcon />
                <Typography sx={{
                    textTransform: 'capitalize',
                    fontSize: 11,
                    color: '#00aeef'
                }}>Download multiple reports</Typography>
            </Button>
            <Box style={{ marginBottom: 10 }}>
                <Typography
                    variant="subtitle1"
                    component="span"
                    style={{
                        fontSize: 12,
                        lineHeight: '18px',
                        display: 'block',
                        fontWeight: 400,
                        color: '#09334B',
                    }}
                >
                    IWRM survey (SDG 6.5.1)
                </Typography>
                <Stack flexDirection={'row'}>
                    {
                        year === 2017 && (
                            participatedInSurvey2017 === 'YES' ? (
                                <Typography
                                    variant="caption"
                                    component="a"
                                    style={{ fontSize: 10, cursor: 'pointer', color: '#00aeef' }}
                                    onClick={() => downloadFullReport(year, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023)}
                                >
                                    6.5.1 survey 2017
                                </Typography>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    style={{ fontSize: 10, display: 'block' }}
                                >
                                    6.5.1 survey 2017
                                </Typography>
                            )
                        )
                    }
                    {
                        year === 2020 && (
                            participatedInSurvey2020 === 'YES' ? (
                                <Typography
                                    variant="caption"
                                    component="a"
                                    style={{ fontSize: 10, cursor: 'pointer', color: '#00aeef' }}
                                    onClick={() => downloadFullReport(year, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023)}
                                >
                                    6.5.1 survey 2020
                                </Typography>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    style={{ fontSize: 10, display: 'block' }}
                                >
                                    6.5.1 survey 2020
                                </Typography>
                            )
                        )
                    }
                    {
                        year === 2023 && (
                            participatedInSurvey2023 === 'YES' ? (
                                <Typography
                                    variant="caption"
                                    component="a"
                                    style={{ fontSize: 10, cursor: 'pointer', color: '#00aeef' }}
                                    onClick={() => downloadFullReport(year, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023)}
                                >
                                    6.5.1 survey 2023
                                </Typography>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    style={{ fontSize: 10, display: 'block' }}
                                >
                                    6.5.1 survey 2023
                                </Typography>
                            )
                        )
                    }
                </Stack>
            </Box>
            <Box style={{ marginBottom: 10 }}>
                <Typography
                    variant="subtitle1"
                    component="span"
                    style={{
                        fontSize: 12,
                        lineHeight: '18px',
                        display: 'block',
                        fontWeight: 400,
                        color: '#09334B',
                    }}
                >
                    Country summary (6.5.1)
                </Typography>
                <Box
                    style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, padding: 0 }}
                >
                    {
                        year === 2017 && (
                            participatedInSurvey2017 === 'YES'
                                ? globalVar.AVAILABLE_LANGUAGES.map((language, idx) => (
                                    <Typography
                                        variant="caption"
                                        component="a"
                                        style={{ fontSize: 10, cursor: 'pointer', color: '#00aeef' }}
                                        onClick={() => downloadSummaries(year, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023, language)}
                                        key={idx}
                                    >
                                        {language}
                                    </Typography>
                                ))
                                : globalVar.AVAILABLE_LANGUAGES.map((language, idx) => (
                                    <Typography
                                        variant="caption"
                                        component="span"
                                        style={{ fontSize: 10 }}
                                        key={idx}
                                    >
                                        {language}
                                    </Typography>
                                ))
                        )
                    }
                    {
                        year === 2020 && (
                            participatedInSurvey2020 === 'YES'
                                ? globalVar.AVAILABLE_LANGUAGES.map((language, idx) => (
                                    <Typography
                                        variant="caption"
                                        component="a"
                                        style={{ fontSize: 10, cursor: 'pointer', color: '#00aeef' }}
                                        onClick={() => downloadSummaries(year, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023, language)}
                                        key={idx}
                                    >
                                        {language}
                                    </Typography>
                                ))
                                : globalVar.AVAILABLE_LANGUAGES.map((language, idx) => (
                                    <Typography
                                        variant="caption"
                                        component="span"
                                        style={{ fontSize: 10 }}
                                        key={idx}
                                    >
                                        {language}
                                    </Typography>
                                ))
                        )
                    }
                    {
                        year === 2023 && (
                            participatedInSurvey2023 === 'YES'
                                ? globalVar.AVAILABLE_LANGUAGES.map((language, idx) => (
                                    <Typography
                                        variant="caption"
                                        component="a"
                                        style={{ fontSize: 10, cursor: 'pointer', color: '#00aeef' }}
                                        onClick={() => downloadSummaries(year, countryName, participatedInSurvey2017, participatedInSurvey2020, participatedInSurvey2023, language)}
                                        key={idx}
                                    >
                                        {language}
                                    </Typography>
                                ))
                                : globalVar.AVAILABLE_LANGUAGES.map((language, idx) => (
                                    <Typography
                                        variant="caption"
                                        component="span"
                                        style={{ fontSize: 10 }}
                                        key={idx}
                                    >
                                        {language}
                                    </Typography>
                                ))
                        )
                    }
                </Box>
            </Box>
            <Box style={{ marginBottom: 10 }}>
            {(isStakeholderReportAvailable || isActionPlansAvailable || currentAvailableFactSheets.filter(item => item === countryName)
                        .length > 0) && (
                <Typography
                    variant="subtitle1"
                    component="span"
                    style={{
                        fontSize: 12,
                        lineHeight: '18px',
                        display: 'block',
                        fontWeight: 400,
                        color: '#09334B',
                    }}
                >
                    Other reports
                </Typography>
            )}
                <Box>
                    {isStakeholderReportAvailable && (
                        <Typography
                            variant="caption"
                            component="a"
                            style={{ fontSize: 10, cursor: 'pointer', color: '#00aeef', display: 'block' }}
                            onClick={() => downloadStakeholderConsultation(year, countryName)}>
                            Stakeholder consultation report
                        </Typography>
                    )}
                    

                    {isActionPlansAvailable && (
                        <Typography
                            variant="caption"
                            component="a"
                            style={{ fontSize: 10, cursor: 'pointer', color: '#00aeef', display: 'block' }}
                            onClick={() => downloadActionPlans(year, countryName)}>
                            National IWRM action plan
                        </Typography>
                    )}
                    

                    {currentAvailableFactSheets.filter(item => item === countryName)
                        .length > 0 && (
                        currentAvailableFactSheets
                            .filter(item => item === countryName)
                            .map((item, idx) => (
                                <Typography
                                    variant="caption"
                                    component="a"
                                    style={{ fontSize: 10, cursor: 'pointer', color: '#00aeef' }}
                                    onClick={() => downLoadFactsheet(countryName)}
                                    key={idx}
                                >
                                    Country IWRM factsheet
                                </Typography>
                            ))
                    )}
                </Box>
            </Box>
        </Grid >
    )
}

export default ReportSdg