import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Modal = ({ open, onClose }) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 700 }}>
        Indicator 6.5.1. global reporting results
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '14px' }}>
        The interactive map shows SDG indicator 6.5.1 global reporting results for years 2017 (baseline reporting), 2020, and 2023 (latest reporting round). The underlying country reports, including national scores and explanatory narrative text, can be downloaded from the <Link href='/country-reports' underline="none" target="_blank" rel="noopener noreferrer">Country Reports</Link> page.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          position: 'absolute',
          top: '5px',
          right: '5px'
        }}
      >
        <IconButton aria-label="delete" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  )
}

export default Modal