import { Container } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid';
import { Typography } from '@mui/material';

const Admin = () => {

  return (
    <>
      <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid xs={6}>
          <Typography>Logo</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography>Account</Typography>
        </Grid>
        
      </Grid>
      </Container>
      <Container maxWidth={false}><Typography>Dashboard</Typography></Container>
    </>
    
  )
}

export default Admin