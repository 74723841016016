import React, { useEffect, useState, Fragment } from 'react'
import { Helmet } from 'react-helmet';
import { GET_OVERVIEW_URL } from '../../api/IWRMServices'
import {
  Box,
  Typography,
  CardMedia,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Button,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import PropTypes from 'prop-types';
import Search from '../../components/search/Search';
import SdgSummary from '../../components/sdg/SdgSummary';
import DownloadConfiguration from '../../components/downloadConfiguration/DownloadConfiguration';
import Spinner from '../../components/spinner/Spinner';
import globalVar from '../../global-constanta'
import { downloadFullDatasetCountry } from '../../utils/DownloadFunction'
import { useAppContext } from '../../context/AppContext';

const Country = () => {

  const {
    state: { loading, overview },
    action: { setLoading, setOverview }
  } = useAppContext()

  const [searchQuery, setSearchQuery] = useState('')
  const [countryName, setCountryName] = useState('')
  const [isShowDownloadConfiguration, setIsShowDownloadConfiguration] = useState(false);
  const [participatedInSurvey2017, setParticipatedInSurvey2017] = useState('NO');
  const [participatedInSurvey2020, setParticipatedInSurvey2020] = useState('NO');
  const [participatedInSurvey2023, setParticipatedInSurvey2023] = useState('YES');

  // const getOverview = async () => {
  //   setLoading(true)
  //   try {
  //     let res = await fetch(GET_OVERVIEW_URL)
  //     let obj = await res.json();
  //     setOverview(obj.Data)
  //   } catch (error) {

  //   }
  //   setLoading(false)
  // }

  useEffect(() => {
    const getOverview = async () => {
      setLoading(true);
      try {
        let res = await fetch(GET_OVERVIEW_URL);
        let obj = await res.json();
        setOverview(obj.Data);
      } catch (error) {}
      setLoading(false);
    };

    if (overview.length > 0) return;
    getOverview();
  }, [overview, setLoading, setOverview])

  const createData = (item) => {

    let countryName = item.Country.Name
    let countryRegion = item.Country.Description
    let flag
    try {
      flag = require(`../../assets/flags/${countryName.toLowerCase()}.png`);
    } catch (error) {
      flag = null;
    }
    let participatedInSurvey2017 = item.ParticipatedInSurvey[2] ?? 'NO';
    let participatedInSurvey2020 = item.ParticipatedInSurvey[3] ?? 'NO';
    // let participatedInSurvey2023 = item.ParticipatedInSurvey[4] ?? 'NO';

    return {
      countryName,
      countryRegion,
      flag,
      participatedInSurvey2017,
      participatedInSurvey2020,
      participatedInSurvey2023
    };
  }

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
          <TableCell component="th" scope="row" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <CardMedia className="flag" component="img" src={row.flag ? row.flag : '../../assets/flags/.png'} sx={{ width: '30px', marginRight: 2 }} />
            <Typography fontSize={12}>
              {row.countryName}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography fontSize={12}>
              {row.countryRegion}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <SdgSummary
                country={row.countryName}
                setCountryName={setCountryName}
                isShowDownloadConfiguration={isShowDownloadConfiguration}
                setIsShowDownloadConfiguration={setIsShowDownloadConfiguration}
                participatedInSurvey2017={row.participatedInSurvey2017}
                participatedInSurvey2020={row.participatedInSurvey2020}
                participatedInSurvey2023={row.participatedInSurvey2023}
                setParticipatedInSurvey2017={setParticipatedInSurvey2017}
                setParticipatedInSurvey2020={setParticipatedInSurvey2020}
                setParticipatedInSurvey2023={setParticipatedInSurvey2023}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      countryName: PropTypes.string.isRequired,
      countryRegion: PropTypes.string.isRequired,
    }).isRequired,
  };

  const rows = overview?.filter(item => item.Country.Name.toLowerCase().includes(searchQuery) || item.Country.Description.toLowerCase().includes(searchQuery)
  ).map(item => (
    createData(item)
  ))

  return (
    <Fragment>
      <Helmet>
        <title>IWRM Data Portal - Country Reports</title>
        <meta name="description" content="Global progress on implementation of Integrated Water Resources Management (IWRM) and Sustainable Development Goal (SDG) indicator 6.5.1 countries reports." />
        <meta name="keywords" content="IWRM, SDG 6, SDG 6.5.1, water management, sustainable development, IWRM country reports, SDG 6 Country report, SDG 6.5.1 country reports" />
      </Helmet>
      <div className="main-container" style={{ padding: '0px 20px' }}>
        <div className="one-column">
          <div className="support-programme-title">Country Reports</div>
          <Stack flexDirection="row" justifyContent="space-between">
            <Box>
              <Search
                title="Search for country or region"
                onSearch={(e) => setSearchQuery(e.target.value.toLowerCase())}
              />
            </Box>
            <Box>
              <Button variant="contained" onClick={downloadFullDatasetCountry}>
                <GetAppIcon style={{ marginRight: 5 }} />
                <Typography variant="caption" component="span" fontSize={10}>
                  Download full dataset (.xlsx)
                </Typography>
              </Button>
            </Box>
          </Stack>
          <Box sx={{ marginTop: 2 }}>
            <TableContainer component={Paper} style={{ maxHeight: '450px', overflowY: 'auto' }}>
              <Table aria-label="collapsible table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Country Name</TableCell>
                    <TableCell>Region</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    rows.length > 0 ? (
                      rows
                        .filter(
                          country =>
                            !globalVar.COUNTRY_EXCEPTION.includes(country.countryName),
                        )
                        .sort((a, b) => {
                          return a.countryName.localeCompare(b.countryName);
                        }).map((row, i) => (
                          <Row key={i} row={row} />
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                          No data available
                        </TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            {loading && <Spinner />}
          </Box>
        </div>

        {isShowDownloadConfiguration && (
          <DownloadConfiguration
            countryName={countryName}
            setIsShowDownloadConfiguration={setIsShowDownloadConfiguration}
            participatedInSurvey2017={participatedInSurvey2017}
            participatedInSurvey2020={participatedInSurvey2020}
            participatedInSurvey2023={participatedInSurvey2023}
            currentAvailableFactSheets={globalVar.CURRENT_AVAILABLE_FACTSHEETS}
          />
        )}
      </div>
    </Fragment>
  )
}

export default Country