import React, { useState, useEffect, useRef } from 'react'
import { useLocation, Link, NavLink } from "react-router-dom";
import { Stack, Box, Button, MenuItem, Typography } from '@mui/material';
import { Home, ArrowDropDown, Close, Menu } from '@mui/icons-material';
import { menus, supportOrganization } from '../menu/Menu'
import { GAPush } from '../../utils/DownloadAnalytic'

const Header = () => {

    const location = useLocation()
    const [anchorel, setanchorel] = useState(null);
    const [open, setOpen] = useState(false);
    const buttonRef = useRef(null);
    const [isSideMenuActive, setIsSideMenuActive] = useState(false)

    const handleClick = () => {
        setanchorel(buttonRef.current);
        setOpen(!open);
    };

    const handleClose = () => {
        setanchorel(null);
        setOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (buttonRef.current && !buttonRef.current.contains(event.target)) {
                handleClose();
            }
        };
        if (open) {
            window.addEventListener('click', handleOutsideClick);
        } else {
            window.removeEventListener('click', handleOutsideClick);
        }
        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [open]);

    const isNavLinkActive = (path) => {
        if (!location) return false;
        if (path === '/') {
            return location.pathname === '/';
        }
        return location.pathname.startsWith(path);
    };

    return (
        <Stack
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            width='100%'
            height='100px'
            position='absolute'
            top={0}
            left={0}
            padding='0px 20px'
            zIndex={10}
            backgroundColor='white'
            boxSizing='border-box'
            boxShadow='rgba(17, 17, 26, 0.1) 0px 2px 0px'
        >
            <Stack
                flexDirection='row'
                flex={1}
                justifyContent='flex-start'
                alignItems='center'
                minWidth='300px'
            >
                {
                    supportOrganization.map(item => (
                        item.id === 4 && (
                            <img
                                src={item.src}
                                alt={item.alt}
                                style={{
                                    height: '75px',
                                    marginRight: '10px'
                                }}
                                key={item.id}
                            />
                        )
                    ))
                }
                <NavLink
                    to="/"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#09334B',
                        textDecoration: 'none'
                    }}
                >
                    <Typography variant="h2" fontWeight={600} fontSize='18px'>
                        IWRM Data Portal
                    </Typography>
                    <Typography fontWeight={400} fontSize='12px'>
                        Tracking SDG 6.5.1
                    </Typography>
                </NavLink>
            </Stack>
            <div className={isSideMenuActive ? 'menu-center mobileShow' : 'menu-center mobileHide'}>
                <div className="cwas-logo mobileOnly">
                    {supportOrganization.filter(res => res.id === 4).map(item => (
                        <img
                            src={item.src}
                            className={item.class}
                            alt={item.alt}
                            key={item.alt}
                        />
                    )
                    )}
                    <div className="brand">
                        <div className="brand-title">IWRM Data Portal</div>
                        <div className="brand-desc">Tracking SDG 6.5.1</div>
                    </div>
                </div>
                {
                    menus.map(menu => {
                        return(
                        menu.path !== '#' ? (
                            <Box className={menu.path === '/interactive-map' || menu.path === '/country-reports' ? 'mobileOnly' : null} sx={{ position: 'relative' }} key={menu.id}>
                                <Button
                                    component={NavLink}
                                    to={menu.path}
                                    end={menu.path === '/'}
                                    sx={{
                                        color: isNavLinkActive(menu.path) ? '#00aeef' : '#09334B',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        textTransform: 'capitalize',
                                        textDecoration: 'none',
                                        borderRadius: 0,
                                        borderBottom: isNavLinkActive(menu.path) ? '3px solid #00aeef' : 'none',
                                        padding: '40px 10px',
                                        '&:hover': {
                                            color: '#00aeef',
                                            backgroundColor: 'transparent'
                                        },
                                    }}
                                    onClick={() => {
                                        const pathMap = {
                                            '/': 'home',
                                            '/country-reports': 'country_reports',
                                            '/interactive-map': 'interactive_map',
                                            '/publications/global': 'resource_library',
                                            '/support-programme': 'support_programme',
                                            '/data-collection': 'data_collection',
                                            '/about': 'about',
                                          };
                                        
                                          const page = pathMap[menu.path];
                                          GAPush('nav_click', 'page_view', page);
                                          setIsSideMenuActive(false);
                                    }}
                                >
                                    {menu.icon && <Home className="desktopOnly" sx={{ fontSize: 16 }} />}
                                    <Typography marginLeft={'4px'} fontSize={'12px'}>{menu.name}</Typography>
                                </Button>
                            </Box>
                        ) : (
                            <Box className="desktopOnly" sx={{ position: 'relative' }} key={menu.id}>
                                <Button
                                    id="basic-button"
                                    onClick={handleClick}
                                    ref={buttonRef}
                                    sx={{
                                        color: isNavLinkActive(menu.path) ? '#00aeef' : '#09334B',
                                        fontSize: '12px',
                                        fontWeight: 'normal',
                                        textTransform: 'capitalize',
                                        textDecoration: 'none',
                                        borderRadius: 0,
                                        borderBottom: isNavLinkActive(menu.path) ? '3px solid #00aeef' : 'none',
                                        padding: '40px 10px',
                                        '&:hover': {
                                            color: '#00aeef',
                                            backgroundColor: 'transparent'
                                        },
                                    }}
                                >
                                    {menu.name}
                                    <ArrowDropDown sx={{ fontSize: 16 }} />
                                </Button>
                                <Box
                                    anchorel={anchorel}
                                    open={open}
                                    onClick={(e) => e.stopPropagation()}
                                    sx={{
                                        position: 'absolute',
                                        top: 70,
                                        left: 0,
                                        display: anchorel === null && 'none',
                                        backgroundColor: 'white',
                                        zIndex: 1
                                    }}>
                                    {
                                        menu.subnav.map(item => (
                                            <MenuItem
                                                component={NavLink}
                                                to={item.path}
                                                onClick={() => {
                                                    const pathMap = {
                                                        '/country-reports': 'country_reports',
                                                        '/interactive-map': 'interactive_map'
                                                    };
                                                    
                                                    const page = pathMap[item.path];
                                                    GAPush('nav_click', 'page_view', page);
                                                    handleClose()
                                                }}
                                                sx={{
                                                    color: '#09334B',
                                                    fontSize: '12px',
                                                    textTransform: 'capitalize',
                                                    '&:hover': {
                                                        color: '#00aeef',
                                                        backgroundColor: 'transparent'
                                                    },
                                                    '&.active': {
                                                        color: '#00aeef',
                                                    },
                                                }}
                                                key={item.name}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Box>
                            </Box>
                        )
                    )})
                }
            </div>
            <Stack
                flexDirection='row'
                flex={2}
                justifyContent='flex-end'
                alignItems='center'
            >
                {
                    supportOrganization.map(item => (
                        item.id !== 4 && (
                            <Link
                                href={item.href}
                                key={item.id}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={item.src}
                                    alt={item.alt}
                                    style={{
                                        height: item.alt !== 'gwp' ? item.alt === 'un-envi' ? '120px': '75px' : '40px',
                                        marginRight: '10px'
                                    }}
                                />
                            </Link>
                        )
                    ))
                }
                <div
                    className="header-burger-menu"
                    onClick={() => setIsSideMenuActive(!isSideMenuActive)}
                >
                    {isSideMenuActive ? (
                        <Close style={{ fontSize: 40 }} />
                    ) : (
                        <Menu style={{ fontSize: 40 }} />
                    )}
                </div>
            </Stack>
            {/* {
                <div className={isSideMenuActive ? 'mobile-active' : 'mobile-hide'}>
                    <div className="cwas-logo">
                        {supportOrganization.filter(res => res.id === 4).map(item => (
                            <img
                                src={item.src}
                                className={item.class}
                                alt={item.alt}
                                key={item.alt}
                            />
                        )
                        )}
                        <div className="brand">
                            <div className="brand-title">IWRM Data Portal</div>
                            <div className="brand-desc">Tracking SDG 6.5.1</div>
                        </div>
                    </div>
                    {mobileMenus.map(item => (
                        <NavLink
                            to={item.path}
                            end={item.path === '/'}
                            key={item.id}
                            onClick={() => setIsSideMenuActive(false)}
                        >
                            {item.name}
                        </NavLink>
                    ))}
                </div>
            } */}
        </Stack>
    )
}

export default Header