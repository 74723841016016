import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { useForm } from 'react-hook-form';
import {
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  FormControl
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AboutIWRMImage from '../../assets/about/About-IWRM-Data-Portal.png'
import SDGIndicatorImage from '../../assets/about/SDG-indicator-6.5.1.png'

const CustomTab = styled(Tab)(({ theme }) => ({
  fontSize: 12
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const customeStyle = {
  accordionHeader: {
    backgroundColor: '#F2F5F7',
    borderBottom: '2px solid lightgray'
  },
  accordionTitle: {
    color: '#09334B',
    fontSize: 14,
    fontWeight: 700
  },
  accordionContent: {
    fontSize: 12,
    fontWeight: 300,
    color: '#09334B',
    marginBottom: 10
  },
  content: {
    fontSize: 12,
    color: '#09334B',
    marginBottom: 10
  }
}

const About = () => {

  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = data => {
    console.log(data);
    reset()
  };

  return (
    <Fragment>
      <Helmet>
        <title>IWRM Data Portal - About</title>
        <meta name="description" content="The objective of the IWRM Data Portal is to provide an easy access to the global data on the status of implementation of Integrated Water Resources Management (IWRM) to all interested stakeholders." />
        <meta name="keywords" content="IWRM, SDG 6, SDG 6.5.1, water management, sustainable development, about IWRM, about SDG 6, about SDG 6.5.1" />
      </Helmet>
      <div className="main-container" style={{ padding: '0px 20px' }}>
        <div className="one-column">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="publication-type-tabs">
              <CustomTab label="About IWRM Data Portal" {...a11yProps(0)} />
              <CustomTab label="SDG Indicator 6.5.1" {...a11yProps(1)} />
              <CustomTab label="FAQ" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="two-columns">
              <div className="left-side">
                <div className="support-programme-title">About IWRM Data Portal</div>
                <Typography style={customeStyle.content}>
                  The objective of the IWRM Data Portal is to provide an easy access to the global data on the status of implementation of Integrated Water Resources Management (IWRM) to all interested stakeholders.
                </Typography>
                <Typography style={customeStyle.content}>
                  The IWRM implementation data presented on the portal are based on the national reports for <a href='https://sdgs.un.org/goals/goal6' target='_blank' rel='noreferrer noopener'>Sustainable Development Goal (SDG) indicator 6.5.1</a>, measuring the degree of integrated water resources management implementation in countries. The portal hosts more than 190 national reports on SDG indicator 6.5.1 (from reporting in 2017, 2020 and 2023)&nbsp;<NavLink to='/publications/global' target='_blank'>global and regional reports</NavLink>, and the official <NavLink to='/data-collection' target='_blank'>data collection</NavLink>.
                </Typography>
                <Typography style={customeStyle.content}>
                  You can view the data via interactive map or download the full submission documents and their summaries from the <NavLink to='/country-reports' target='_blank'>Country Reports</NavLink> page.
                </Typography>
                <Typography style={customeStyle.content}>The IWRM Data Portal is maintained by <a href='https://www.unepdhi.org/' target='_blank' rel='noreferrer noopener'>UNEP-DHI Centre on Water and Environment</a>, in partnership with <a href='https://www.unep.org/' target='_blank' rel='noreferrer noopener'>UNEP</a> and <a href='https://www.gwp.org/' target='_blank' rel='noreferrer noopener'>GWP</a>.</Typography>
              </div>
              <div className="right-side">
                <img src={AboutIWRMImage} alt="about-iwrm-data-portal" style={{ width: '90%' }} />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="two-columns">
              <div className="left-side">
                <div className="support-programme-title">SDG Indicator 6.5.1</div>
                <Typography style={customeStyle.content}>
                  Sustainable Development Goal (SDG) indicator 6.5.1 is one of the 11 indicators measuring progress on Sustainable Development Goal 6 (Clean Water and Sanitation).
                </Typography>
                <Typography style={customeStyle.content}>
                  National reporting on the indicator is undertaken by the UN Member States roughly every 3 years, with the purpose of tracking progress towards Goal 6 of the 2030 Agenda for Sustainable Development. National reports are submitted to UNEP, the custodian agency for indicator 6.5.1, for quality assurance and further data analysis and dissemination.
                </Typography>
                <Typography style={customeStyle.content}>
                  UN Member States use a national survey containing 33 survey questions across four key dimensions of IWRM to establish the indicator value:
                </Typography>
                  <ol style={customeStyle.content}>
                    <li>Enabling environment</li>
                    <li>Institutions and participation</li>
                    <li>Management instruments</li>
                    <li>Financing</li>
                  </ol>
                <Typography style={customeStyle.content}>
                  Each question is scored on a scale of 0 to 100 (Very low to Very high degree of implementation). The survey document is typically a result of national consultations with relevant stakeholders (e.g. national and subnational line ministries and institutions involved in water resources management and other stakeholders such as NGOs, academia, and business). The consolidated survey is submitted to UNEP – the UN Custodian agency responsible for this indicator – for QA and analysis. UNEP-DHI Centre on Water and Environment supports UNEP in the data quality assurance and analysis.
                </Typography>
                <Typography style={customeStyle.content}>See the 2021 IWRM video <a href='https://www.youtube.com/watch?v=fKgkmXCO7h0' target='_blank' rel='noreferrer noopener'>here</a>.</Typography>
                <Typography style={customeStyle.content}>See reporting <NavLink to='/data-collection' target='_blank'>materials and status here</NavLink>.</Typography>
                <Typography style={customeStyle.content}>See more information on SDG 6 and related indicators via links below:</Typography>
                  <ol style={customeStyle.content}>
                    <li><a href='https://sdgs.un.org/goals' target='_blank' rel='noreferrer noopener'>Sustainable Development Goals</a></li>
                    <li><a href='https://sdgs.un.org/2030agenda' target='_blank' rel='noreferrer noopener'>2030 Agenda</a></li>
                    <li><a href='https://sdgs.un.org/goals/goal6' target='_blank' rel='noreferrer noopener'>Sustainable Development Goal 6 targets and indicators</a></li>
                    <li><a href='https://www.sdg6data.org/' target='_blank' rel='noreferrer noopener'>SDG 6 Data Portal</a></li>
                  </ol>
              </div>
              <div className="right-side" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <img src={SDGIndicatorImage} alt="sdg-indicator-6.5.1" style={{ width: '90%' }} />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="two-columns">
              <div className="left-side" style={{ marginRight: 5 }}>
                <div className="support-programme-title">FAQ</div>
                <div className="accordion-container">
                  <Accordion expanded={expanded === 'panel1'} onChange={handleExpand('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={customeStyle.accordionHeader}
                    >
                      <Typography style={customeStyle.accordionTitle}>What data can I find on this portal?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={customeStyle.accordionContent}>
                        The IWRM Data Portal presents data on SDG indicator 6.5.1 and all data are based on the official country submissions to the United Nations Environment Programme (UNEP) – the custodian agency for this indicator. Data are available in their original format – through the <NavLink to={{ pathname: '/country-reports' }} target='_blank'>country reports</NavLink>, as well as in <NavLink to={{ pathname: '/interactive-map' }} target='_blank' >interactive map</NavLink> format and <NavLink to='/publications/global' target='_blank' >excel databases</NavLink>.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleExpand('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      style={{ backgroundColor: '#F2F5F7' }}
                    >
                      <Typography style={customeStyle.accordionTitle}>What is SDG indicator 6.5.1?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={customeStyle.accordionContent}>
                        Indicator 6.5.1 is one of the indicators under Sustainable Development Goal 6. It measures the degree of integrated water resources management implementation or IWRM. Each country completes a survey instrument with approximately 30 questions, covering different aspects of integrated water resources management. Each question is scored on a scale of 0 – 100, and the indicator score is an average of question scores, also on a scale of 0 – 100. The global indicator 6.5.1 score is an average of country scores. Download full methodology for indicator form here.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel3'} onChange={handleExpand('panel3')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      style={customeStyle.accordionHeader}
                    >
                      <Typography style={customeStyle.accordionTitle}>Can I get support to collect data in my country?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={customeStyle.accordionContent}>
                        SDG indicator 6.5.1 provides support to all national Focal Points in their data collection efforts via a dedicated SDG 6.5.1 Help Desk mailbox <a href='mailto:iwrmsdg651@un.org' rel='noreferrer noopener'>iwrmsdg651@un.org</a>. In addition, a number of open access support options are available via the SDG6 IWRM Support Programme initiative to Focal Points and other stakeholders. Please visit the <NavLink to={{ pathname: '/support-programme' }} target='_blank'>IWRM Support Programme</NavLink> page for more details.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel4'} onChange={handleExpand('panel4')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      style={customeStyle.accordionHeader}
                    >
                      <Typography style={customeStyle.accordionTitle}>When will the data be updated?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={customeStyle.accordionContent}>
                        The official reporting on indicator SDG 6.5.1 by the UN Member States takes place approximately every 3 years. The next data collection round is expected to take place in 2026. A number of support options are available to National Focal points and other stakeholders to facilitate the stakeholder consultations and data collection in the countries.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel5'} onChange={handleExpand('panel5')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      style={customeStyle.accordionHeader}
                    >
                      <Typography style={customeStyle.accordionTitle}>I have more questions, whom should I contact?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={customeStyle.accordionContent}>
                        For further inquiries regarding the portal and the SDG 6.5.1 data contact iwrmsdg651@un.org or UNEP-DHI team via isno@dhigroup.com
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <div className="right-side" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 5 }}>
                <div className="support-programme-title">Do you have a question?</div>
                <Typography style={customeStyle.content}>Our Help Desk is ready to answer your questions. Please fill in your mail and what the question is about and we will get back to you.</Typography>
                <Box>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <TextField
                        label="Your Name"
                        variant="filled"
                        InputLabelProps={{
                          style: { fontSize: 11 }
                        }}
                        {...register("name", { required: "Name is required" })}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <TextField
                        label="Your Country"
                        variant="filled"
                        InputLabelProps={{
                          style: { fontSize: 11 }
                        }}
                        {...register("country", { required: "Country is required" })}
                        error={!!errors.country}
                        helperText={errors.country?.message}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <TextField
                        label="Your E-mail"
                        variant="filled"
                        InputLabelProps={{
                          style: { fontSize: 11 }
                        }}
                        type="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Invalid email address"
                          }
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <TextField
                        label="Write your questions here"
                        variant="filled"
                        InputLabelProps={{
                          style: { fontSize: 11 }
                        }}
                        multiline
                        rows={4}
                        {...register("questions", { required: "Please enter your question" })}
                        error={!!errors.questions}
                        helperText={errors.questions?.message}
                      />
                    </FormControl>
                    <Button type="submit" variant="contained" color="primary">
                      Send
                    </Button>
                  </form>
                </Box>
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </Fragment>
  )
}

export default About